import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Card, CardContent, CardHeader, Container, Grid, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function Securities() {
  const bull = (
    <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(2.5)' }}>
      •
    </Box>
  );

  return (
    <>
      <Helmet>
        <title> Securities </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Security Terms & conditions
        </Typography>
        <Card>
          <CardHeader>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Security Terms & conditions
            </Typography>
          </CardHeader>
          <CardContent>All Terms and conditions are written below</CardContent>
          <Grid container>
            <Grid item sx={{ pl: 5 }} xs={12} sm={12} md={12}>
              <span style={{paddingRight:4}}>{bull}</span>
              Cybersecurity is the practice of protecting systems, networks, and programs from digital attacks. These
              cyberattacks are usually aimed at accessing, changing, or destroying sensitive information; extorting
              money from users via ransomware; or interrupting normal business processes.
            </Grid>
            <Grid item sx={{ pl: 5 }} xs={12} sm={12} md={12}>
              <span style={{paddingRight:4}}>{bull}</span>
              Cybersecurity is the practice of protecting systems, networks, and programs from digital attacks. These
              cyberattacks are usually aimed at accessing, changing, or destroying sensitive information; extorting
              money from users via ransomware; or interrupting normal business processes.
            </Grid>
            <Grid item sx={{ pl: 5 }} xs={12} sm={12} md={12}>
              <span style={{paddingRight:4}}>{bull}</span>
              Cybersecurity is the practice of protecting systems, networks, and programs from digital attacks. These
              cyberattacks are usually aimed at accessing, changing, or destroying sensitive information; extorting
              money from users via ransomware; or interrupting normal business processes.
            </Grid>
            <Grid item sx={{ pl: 5 }} xs={12} sm={12} md={12}>
              <span style={{paddingRight:4}}>{bull}</span>
              Cybersecurity is the practice of protecting systems, networks, and programs from digital attacks. These
              cyberattacks are usually aimed at accessing, changing, or destroying sensitive information; extorting
              money from users via ransomware; or interrupting normal business processes.
            </Grid>
          </Grid>
        </Card>
      </Container>
    </>
  );
}
