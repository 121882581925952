import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { deleteQueryById, getQueries } from '../../Services/queryServices';

export default function CustomerQuries() {
  const navigate = useNavigate();
  const [quries, setQuries] = useState([]);
  const fetchQuries = async () => {
    try {
      const data = await getQueries();
      console.log(data);
      setQuries(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchQuries();
  }, []);
  const handleClick = (row) => {
    navigate('/dashboard/query-details', {
      state: {
        query: row,
      },
    });
  };
  const handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete this Query?');

      if (confirmDelete) {
        const response = await deleteQueryById(id);

        if (response.status === 200) {
          setQuries(quries.filter((query) => query._id !== id));
        } else {
          console.error('Failed to delete Query');
        }
      }
    } catch (error) {
      console.error('Error deleting blog:', error);
    }
  };
  return (
    <TableContainer style={{ overflow: 'auto' }} component={Paper}>
      <Table sx={{ minWidth: 650, overflow: 'auto' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Phone</TableCell>
            <TableCell align="left">Purpose</TableCell>
            <TableCell align="left">View</TableCell>
            <TableCell align="left">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quries?.map((row, index) => (
            <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row?.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {row?.email}
              </TableCell>
              <TableCell component="th" scope="row">
                {row?.phone}
              </TableCell>
              <TableCell component="th" scope="row">
                {row?.purpose}
              </TableCell>
              <TableCell component="th" scope="row">
                <Button onClick={() => handleClick(row)}>View</Button>
              </TableCell>
              <TableCell component="th" scope="row">
                <Button onClick={() => handleDelete(row?._id)}>Delete</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
