// import React, { useState } from 'react';
// import { TimePicker, message } from 'antd';
// import dayjs from 'dayjs';

// const App = () => {
//   const [value, setValue] = useState(null);

//   const onChange = (time) => {
//     setValue(time);
//   };

//   const onOk = (selectedTime) => {
//     if (selectedTime) {
//       // Convert the selected time into seconds
//       const seconds = selectedTime.hour() * 3600 + selectedTime.minute() * 60 + selectedTime.second();
//       message.success(`Time selected: ${seconds} seconds`);
//     } else {
//       message.error('Please select a time');
//     }
//   };

//   return (
//     <TimePicker
//       value={value}
//       width={'100%'}
//       onChange={onChange}
//       format="mm:ss"
//       showNow={false}
//       onOk={onOk} // Handle OK click
//     />
//   );
// };

// export default App;



import React, { useState, useEffect } from 'react';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';

const Test = () => {
  const [value, setValue] = useState(null);
  const initialValueInSeconds = 360; // Example value in seconds

  useEffect(() => {
    if (initialValueInSeconds) {
      const hours = Math.floor(initialValueInSeconds / 3600);
      const minutes = Math.floor((initialValueInSeconds % 3600) / 60);
      const seconds = initialValueInSeconds % 60;
      const formattedValue = dayjs().hour(hours).minute(minutes).second(seconds);
      setValue(formattedValue);
    }
  }, [initialValueInSeconds]);

  const onChange = (time) => {
    setValue(time);
  };

  const onOk = (selectedTime) => {
    // Handle OK click if needed
  };

  return (
    <TimePicker
      value={value}
      onChange={onChange}
      format="mm:ss"
      showNow={false}
      onOk={onOk}
    />
  );
};

export default Test;
