import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';

const StepperInput = ({label, value, onChange, min, max }) => {
  // Use local state for UI interactions
  const [count, setCount] = useState(value);

  // Update local state when the value prop changes
  useEffect(() => {
    setCount(value);
  }, [value]);

  const increment = () => {
    if (count < max) {
      const newValue = count + 1;
      setCount(newValue);
      onChange(newValue);
    }
  };

  const decrement = () => {
    if (count > min) {
      const newValue = count - 1;
      setCount(newValue);
      onChange(newValue);
    }
  };

  return (
    <div style={{display:'flex', alignItems:'center'}}>
      <p style={{paddingRight:5}}>{label}</p>
      <div>
        <Button
          variant='contained'
          onClick={decrement}
          style={{
            backgroundColor:"#3B63A9",
            maxWidth: '30px',
            maxHeight: '30px',
            minWidth: '30px',
            minHeight: '30px',
            marginRight: 8,
            // backgroundColor: "#E97777",
          }}
        >
          <h3>-</h3>
        </Button>
        <span>{count}</span>
        <Button
          variant='contained'
          onClick={increment}
          style={{
            backgroundColor:"#3B63A9",
            maxWidth: '30px',
            maxHeight: '30px',
            minWidth: '30px',
            minHeight: '30px',
            marginLeft: 8,
            // backgroundColor: "#E97777",
          }}
        >
          <h3>+</h3>
        </Button>
      </div>
    </div>
  );
};

export default StepperInput;
