import { Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BASE_URL from '../../BASE_URL';
import { getFormats } from '../../Services/axialServices';

// import { useState } from 'react';

function createData(videoTitle, resolution, framerate, format) {
  return { videoTitle, resolution, framerate, format };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function AllTags() {
  const [audioTags, setAudioTags] = useState([]);

  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchAudioTags = async (page = 1, limit = 20) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/skybox/get-skyboxes`);
      //   console.log(data)
      setAudioTags(response.data);
    } catch (err) {
      setError('Error fetching audio tags');
    }
  };

  useEffect(() => {
    fetchAudioTags();
  }, []);

  const handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete this Skybox?');

      if (confirmDelete) {
        const response = await axios.delete(`${BASE_URL}/api/skybox/delete-skybox/${id}`);

        if (response.status === 200) {
          // alert('Video deleted successfully');
          setAudioTags(audioTags.filter((tag) => tag?._id !== id));
        } else {
          console.error('Failed to delete audio tag');
        }
      }
    } catch (error) {
      console.error('Error deleting audio tag:', error);
    }
  };

  const handleCilck = () => {
    navigate('/dashboard/add-skybox');
    // console.log('ammb a yr ');
  };
  return (
    <>
      <h1>SkyBox</h1>
      <Button variant="contained" sx={{ float: 'right' }} onClick={() => handleCilck()}>
        Add SkyBox
      </Button>
      <TableContainer style={{ overflow: 'auto' }} component={Paper}>
        <Table sx={{ minWidth: 650, overflow: 'auto' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>SkyBox Title</TableCell>
              <TableCell align="left">View</TableCell>
              <TableCell align="left">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {audioTags?.map((row, index) => (
              <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row?.title}
                </TableCell>
                <TableCell component="th" scope="row">
                  <a href={row?.link} target="_blank" rel="noreferrer">
                    View
                  </a>
                </TableCell>
                <TableCell align="left">
                  <Button
                  onClick={() => handleDelete(row?._id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
