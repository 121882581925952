import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'antd';
import axios from 'axios';

// Your initial data
// const data = [
//   {
//     parent: {
//       _id: '663b757e29b189142e1a6206',
//       name: 'hi',
//     },
//     children: [
//       {
//         _id: '663b75a029b189142e1a6209',
//         name: 'hdhd',
//       },
//       {
//         _id: '663cbc12c461e0751b582e3b',
//         name: 'hknksd',
//       },
//       {
//         _id: '663cbc2bc461e0751b582e3d',
//         name: 'kkdkll',
//       },
//     ],
//   },
//   {
//     parent: {
//       _id: '663b75ee29b189142e1a620c',
//       name: 'ddr',
//     },
//     children: [],
//   },
//   {
//     parent: {
//       _id: '663b75ff29b189142e1a620e',
//       name: 'without parent',
//     },
//     children: [
//       {
//         _id: '663b8eb729b189142e1a6239',
//         name: 'hijj',
//       },
//     ],
//   },
//   {
//     parent: {
//       _id: '663b917129b189142e1a6240',
//       name: 'Green Screen',
//     },
//     children: [
//       {
//         _id: '663b91bc29b189142e1a6245',
//         name: 'fire',
//       },
//     ],
//   },
// ];

// Function to transform your data into TreeSelect format

const App = () => {

  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/category/catagories-with-children");
        setCategories(response.data); // Store the fetched data
      } catch (err) {
        setError("Error fetching categories with children"); // Handle errors
      } finally {
        setIsLoading(false); // Loading is complete
      }
    };

    fetchCategories(); // Trigger the data fetch
  }, []);

  const transformToTreeData = (data) => {
    return data?.map((entry) => {
      const parent = {
        value: entry.parent._id, // ID is used for the value
        title: entry.parent.name, // The name is used for the title
        children: entry.children.map((child) => ({
          value: child._id, // Child ID
          title: child.name, // Child name
        })),
      };

      return parent;
    });
  };
  const [value, setValue] = useState();
  const [treeData, setTreeData] = useState([]); // Initialize with empty array

  useEffect(() => {
    // Transform the data on component mount
    const transformedData = transformToTreeData(categories);
    console.log(categories)
    setTreeData(transformedData); // Update state with transformed data
  }, [categories]); // Empty dependency array to ensure this only runs once

  const onChange = (newValue) => {
    console.log(newValue); // Logging the selected value(s)
    setValue(newValue); // Update the value state
  };

  return (
    <TreeSelect
      showSearch
      style={{ width: '100%' }}
      value={value} // The selected value(s)
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder="Please select"
      allowClear
      multiple // Allows selecting multiple options
      treeDefaultExpandAll
      onChange={onChange} // Event handler for changes
      treeData={treeData} // The transformed data
    />
  );
};

export default App;
