import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

// @mui
import { LoadingButton } from '@mui/lab';
import { Container, Grid, Typography } from '@mui/material';
// import { TreeSelect } from 'antd';
import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Select, TimePicker, TreeSelect, message } from 'antd';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
// import React, { useState } from 'react';
// import {} from 'antd';
import ReactQuill from 'react-quill';
import BASE_URL from '../../BASE_URL';

import 'react-quill/dist/quill.snow.css';

// ----------------------------------------------------------------------

const CheckboxGroup = Checkbox.Group;
const { Option } = Select;
const selectTimeUnit = [
  { value: 'sec', label: 'sec' },
  { value: 'min', label: 'min' },
];
const selectSizeUnit = [
  { value: 'MB', label: 'MB' },
  { value: 'GB', label: 'GB' },
];
const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline'], // Basic formatting
  [{ list: 'ordered' }, { list: 'bullet' }], // List options
  [{ align: ['', 'center', 'right', 'justify'] }], // Text alignment options
  ['link'], // Add link button to the toolbar
];

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'align',
  'link', // Ensure "link" format is included
];

export default function Reports() {
  const [openFilter, setOpenFilter] = useState(false);
  const [content, setContent] = useState('');
  const navigate = useNavigate();
  const handleChange = (value) => {
    setContent(value);
  };
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const [number, setNumber] = useState(0);
  const editorRef = useRef(null);
  const [modelTitle, setModelTitle] = useState('');
  const [categories, setCategories] = useState();
  // const [fileSize, setFileSize] = useState('');
  // const [timeDuration, setTimeDuration] = useState('');
  // const [description, setDescription] = useState('');
  const [videolink, setVideoLink] = useState('');
  const [resolution, setResolution] = useState('1080p');
  const [framerate, setFramerate] = useState('29.97fps');
  const [format, setFormat] = useState('h.264');
  const [previewFile, setPreviewFile] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [sizeUnit, setSizeUnit] = useState('MB');
  const [sizeValue, setSizeValue] = useState(0);
  const [durationUnit, setDurationUnit] = useState('sec');
  const [durationValue, setDurationValue] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [FetchedTags, setFetchedTags] = useState([]);
  const [downloadableFiles, setDownloadableFiles] = useState([{ format: 'Select Format', filesize: '', link: '' }]);
  const [size, setSize] = useState('middle');
  const options = FetchedTags?.map((tag) => ({
    value: tag?.name,
    label: tag?.name,
  }));

  const [modelDetails, setModelDetails] = useState([
    { label: 'Animated', value: false },
    { label: 'Textured', value: false },
    { label: 'Rigged', value: false },
    { label: 'Materials', value: false },
    { label: 'LowPoly', value: false },
  ]);

  const handleSubmit = async (values) => {
    console.log('antd values', values, content);
    const des = editorRef.current.getContent();
    const modelDetailsObject = modelDetails.reduce((acc, detail) => {
      acc[detail.label] = detail.value;
      return acc;
    }, {});
    const modelData = {
      title: values.title,
      modelDetail: modelDetailsObject,
      previewlink: values.link,
      skybox: values.skybox,
      categories,
      tags: values?.tags,
      description: des,
      downloadableFiles,
    };
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/api/model/add-model`, modelData, {});
      if (response.status === 201) {
        // window.location.reload();
        navigate('/dashboard/model-list');
      }
      // if (response.status === 201) {
      //   setVideoTitle('');
      //   setCategories(null);
      //   setVideoLink('');
      //   setPreviewFile('');
      //   setSizeUnit('MB');
      //   setSizeValue(0);
      //   setDurationUnit('sec');
      //   setDurationValue(0);
      //   setThumbnail(null);
      // }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === 'Model title must be unique'
      ) {
        alert('Model title must be unique');
      } else {
        alert('Error adding video');
      }
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const [videos, setVideos] = useState([]);
  const [error, setError] = useState(null);

  const [categoriesData, setCategoriesData] = useState([]);
  const [formats, setFormats] = useState([]);
  const [skyBoxes, setSkyBoxes] = useState([]);
  // const [categories, setCategories] = useState([]);
  // const [isLoading, setIsLoading] = useState(true)
  // const [error, setError] = useState(null);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/model/categories-with-children`);
        setCategoriesData(response.data); // Store the fetched data
        console.log(response.data);
      } catch (err) {
        setError('Error fetching categories with children'); // Handle errors
      } finally {
        setIsLoading(false); // Loading is complete
      }
    };
    const fetchFormats = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/model/get-formats`);
        setFormats(response.data); // Store the fetched data
        console.log(response.data);
      } catch (err) {
        setError('Error fetching categories with children'); // Handle errors
      } finally {
        setIsLoading(false); // Loading is complete
      }
    };

    const fetchSkyBoxes = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/skybox/get-skyboxes`);
        setSkyBoxes(response.data); // Store the fetched data
        // console.log(response.data);
      } catch (err) {
        setError('Error fetching categories with children'); // Handle errors
      } finally {
        setIsLoading(false); // Loading is complete
      }
    };
    fetchFormats();
    fetchCategories();
    fetchSkyBoxes();
  }, []);

  useEffect(() => {
    const fetctchTags = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/model/get-tags`);
        setFetchedTags(response.data); // Store the fetched data
        console.log('tags are', response.data);
      } catch (err) {
        setError('Error fetching categories with children'); // Handle errors
        console.log(err);
      }
    };

    fetctchTags();
  }, []);

  const transformToTreeData = (data) => {
    return data?.map((entry) => {
      const parent = {
        value: entry.parent._id, // ID is used for the value
        title: entry.parent.name, // The name is used for the title
        children: entry.children.map((child) => ({
          value: child._id, // Child ID
          title: child.name, // Child name
        })),
      };

      return parent;
    });
  };
  // const [value, setValue] = useState();
  const [treeData, setTreeData] = useState([]); // Initialize with empty array

  useEffect(() => {
    // Transform the data on component mount
    const transformedData = transformToTreeData(categoriesData);
    // console.log(categories);
    setTreeData(transformedData); // Update state with transformed data
  }, [categoriesData]); // Empty dependency array to ensure this only runs once

  const onChange = (newValue) => {
    console.log(newValue); // Logging the selected value(s)
    setCategories(newValue); // Update the value state
  };

  const handleFileChange = (event) => {
    setThumbnail(event.target.files[0]);
  };
  const onFinish = (values) => {
    console.log('Received values:', values);
    // Here you can submit the form values to your backend or perform any other action
  };
  const handleResolution = (value) => {
    console.log('Selected resolution:', value);
    setResolution(value);
  };
  const handleFrameRate = (value) => {
    console.log('Selected Frame Rate:', value);
    setFramerate(value);
  };
  const handleFormat = (value) => {
    console.log('Selected Format:', value);
    setFormat(value);
  };

  const handleSizeUnitChange = (selectedUnit) => {
    setSizeUnit(selectedUnit);
  };

  const handleSizeValueChange = (inputValue) => {
    setSizeValue(inputValue);
  };

  const handleDurationUnitChange = (selectedUnit) => {
    setDurationUnit(selectedUnit);
  };

  const handleDurationValueChange = (inputValue) => {
    setDurationValue(inputValue);
  };

  const [pickerValue, setPickerValue] = useState(null);
  const onDurationChange = (time) => {
    setPickerValue(time);
  };

  const onOk = (selectedTime) => {
    if (selectedTime) {
      // Convert the selected time into seconds
      const seconds = selectedTime.hour() * 3600 + selectedTime.minute() * 60 + selectedTime.second();
      setDurationValue(seconds);
      message.success(`Time selected: ${seconds} seconds`);
    } else {
      message.error('Please select a time');
    }
  };

  const handleCheckboxChange = (checkedValue) => {
    const updatedOptions = modelDetails.map((option) =>
      option.label === checkedValue ? { ...option, value: !option.value } : option
    );
    setModelDetails(updatedOptions);
    // console.log(options);
  };

  const handleInputChange = (index, field, value) => {
    const updatedFiles = [...downloadableFiles];
    updatedFiles[index][field] = value;
    setDownloadableFiles(updatedFiles);
  };

  const addNewFileFields = () => {
    setDownloadableFiles([...downloadableFiles, { format: '', filesize: '', link: '' }]);
  };

  const removeFileFields = (index) => {
    const updatedFiles = downloadableFiles.filter((_, i) => i !== index);
    setDownloadableFiles(updatedFiles);
  };

  return (
    <>
      <Helmet>
        <title> Add Model </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Videos data list
        </Typography>

        <Form
          name="wrap"
          onFinish={handleSubmit}
          labelCol={{
            flex: '110px',
          }}
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 1,
          }}
          colon={false}
          style={{
            width: '100%',
          }}
        >
          <Form.Item
            label="Model Title"
            name="title"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              placeholder="Model Title"
              // value={modelTitle}
              // defaultValue={modelTitle}
              // onChange={(e) => setModelTitle(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: '35px' }}
            label={
              <div className="query-label" style={{ marginTop: 0 }}>
                Model Details
              </div>
            }
            name="purpose"
            //
            rules={[{ required: true, message: 'Please input your Purpose' }]}
          >
            {/* <CheckboxGroup options={options} /> */}
            <Row gutter={16}>
              {modelDetails.map((option, index) => (
                <Col span={8} key={option.label}>
                  <Checkbox checked={option.value} onChange={() => handleCheckboxChange(option.label)}>
                    {option.label}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Form.Item>

          <Form.Item
            label="Tags"
            name="tags"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              mode="tags"
              size={size}
              placeholder="Please select"
              // defaultValue={['a10', 'c12']}
              onChange={handleChange}
              style={{
                width: '100%',
              }}
              options={options}
            />
          </Form.Item>
          <Form.Item
            label="Preview Link"
            name="link"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Preview Link" />
          </Form.Item>
          <Form.Item
            label="Categories"
            name="Category"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TreeSelect
              showSearch
              style={{ width: '100%', cursor: 'pointer' }}
              value={categories} // The selected value(s)
              dropdownStyle={{ overflow: 'auto' }}
              placeholder="Please select"
              allowClear
              multiple // Allows selecting multiple options
              treeDefaultExpandAll
              onChange={onChange} // Event handler for changes
              treeData={treeData} // The transformed data
            />
          </Form.Item>
          <Form.Item
            label="SkyBox"
            name="skybox"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Select SkyBox" style={{ width: '100%' }}>
              {skyBoxes.map((option) => (
                <Option key={option._id} value={option.link}>
                  {option.title}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Add Files" name="files">
            <>
              {downloadableFiles.map((file, index) => (
                <Row gutter={16} key={index} style={{ marginBottom: 16 }}>
                  <Col span={7}>
                    <Select
                      placeholder="Select Format"
                      value={file.format}
                      onChange={(value) => handleInputChange(index, 'format', value)}
                      style={{ width: '100%' }}
                    >
                      {formats.map((option) => (
                        <Option key={option._id} value={option.name}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={7}>
                    <Input
                      placeholder="Filesize"
                      value={file.filesize}
                      onChange={(e) => handleInputChange(index, 'filesize', e.target.value)}
                    />
                  </Col>
                  <Col span={7}>
                    <Input
                      placeholder="Link"
                      value={file.link}
                      onChange={(e) => handleInputChange(index, 'link', e.target.value)}
                    />
                  </Col>
                  {downloadableFiles?.length > 1 && (
                    <Col span={3}>
                      <Button type="link" onClick={() => removeFileFields(index)}>
                        Cancel
                      </Button>
                    </Col>
                  )}
                </Row>
              ))}
              <Button type="dashed" onClick={addNewFileFields}>
                Add More Files
              </Button>
            </>
          </Form.Item>

          <>
            <Editor
              apiKey="9uej4yr18x8j7w9p6vg9m6e73l6uqhuxib8dsl92l0pzh2jt"
              onInit={(evt, editor) => {
                editorRef.current = editor; // Assigning editor reference to useRef variable
              }}
              initialValue="<p>This is the initial content of the editor.</p>"
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  'code',
                  'help',
                  'wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | link image | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
          </>

          <Grid container justifyContent={'center'}>
            <Grid item>
              <Form.Item label=" ">
                {/* <Button type="primary" htmlType="submit">
                  Submit
                </Button> */}
                <LoadingButton type="submit" variant="contained" loading={isLoading}>
                  Submit
                </LoadingButton>
              </Form.Item>
            </Grid>
          </Grid>
          {/* <NumberInput /> */}
          {/* <FileSizeInput /> */}
        </Form>
      </Container>
    </>
  );
}
