import { useState } from 'react';
import { useAuth } from './AuthContext';
import BASE_URL from '../BASE_URL';

const useAuthActions = () => {
  const [error, setError] = useState(null);
  const { login, logout } = useAuth();

  const handleLogin = async (username, password) => {
    try {
      const response = await fetch(`${BASE_URL}/api/admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        login(); // Update the auth state to logged in
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  const handleLogout = () => {
    logout(); // Update the auth state to logged out
  };

  return { handleLogin, handleLogout, error };
};

export default useAuthActions;
