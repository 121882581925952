import { Helmet } from 'react-helmet-async';

// @mui
// import { Container, Typography } from '@mui/material';
import { Button, CircularProgress, Container, FormHelperText, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BASE_URL from '../../BASE_URL';

// ----------------------------------------------------------------------

export default function AudioTags() {
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleAddSkyBox = async () => {
    if (!title) {
      alert('Title is required');
      return;
    }
    if(!link){
        alert('Link is required');
        return;
    }
    setIsLoading(true);

    try {
      const Data = {
        title,
        link,
      };

      await axios.post(`${BASE_URL}/api/skybox/add-skybox`, Data);

      alert('Skybox added successfully');
      navigate('/dashboard/sky-box');
    } catch (err) {
      setError('Error adding category');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>  Add SkyBox </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Add SkyBox
        </Typography>

        <div>
          {/* <form onSubmit={handleAddSkyBox}> */}
          <TextField
            label="Title"
            value={title}
            required
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            sx={{ mb: '10px' }}
          />
          <TextField
            label="Link"
            required
            value={link}
            onChange={(e) => setLink(e.target.value)}
            fullWidth
            sx={{ mb: '10px' }}
          />

          <Button
            sx={{ marginTop: '10px' }}
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleAddSkyBox}
            disabled={isLoading}
            fullWidth
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Add SkyBox'}
          </Button>
        </div>
      </Container>
    </>
  );
}
