import { Helmet } from 'react-helmet-async';
import { useState } from 'react';

// @mui
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone';

// ----------------------------------------------------------------------

const columns = [
  // { field: 'id', headerName: 'ID', width: 300 },
  { field: 'modules', headerName: 'Modules', width: 300 },
  {
    field: 'read',
    headerName: 'Read',
    width: 130,
    renderCell: (params) => <input type="checkbox" checked={params.value} />,
  },
  {
    field: 'write',
    headerName: 'Write',
    width: 130,
    renderCell: (params) => <input type="checkbox" checked={params.value} />,
  },
  {
    field: 'eidt',
    headerName: 'Edit',
    // type: 'number',
    width: 130,
    renderCell: (params) => <input type="checkbox" checked={params.value} />,
  },
  {
    field: 'delete',
    headerName: 'Delete',
    // type: 'number',
    width: 130,
    renderCell: (params) => <input type="checkbox" checked={params.value} />,
  },
  //   {
  //     field: 'fullName',
  //     headerName: 'Full name',
  //     description: 'This column has a value getter and is not sortable.',
  //     sortable: false,
  //     width: 160,
  //     valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  //   },
];

const rows = [
  { id: 1, modules: 'Dashboard', lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, modules: 'Create Tenant', lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, modules: 'Tenant License List', lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, modules: 'Tenant List', lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, modules: 'User Permission', lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, modules: 'Alerts and Notifications', lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, modules: 'Reports', lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, modules: 'Security', lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, modules: 'Help', lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  { id: 9, modules: 'Settings', lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

export default function UserPermission() {
  return (
    <>
      <Helmet>
        <title> User | User Permission </title>
      </Helmet>
      <Container>
        <Grid container>
          <Grid sx={{ padding: 5 }} item xs={12} sm={6} md={6}>
            <Typography variant="h4">Access Permission</Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Give the access permission by selection the checkboxes
            </Typography>
          </Grid>
          <Grid sx={{ padding: 5 }} item xs={12} sm={6} md={6}>
            <Typography variant="h4">Access Permission</Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Give the access permission by selection the checkboxes
            </Typography>
          </Grid>
        </Grid>

        <div style={{ height: 600, width: '100%' }}>
          <DataGrid
          
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            // pageSizeOptions={[5, 15]}
            // checkboxSelection
          />
        </div>
        <Grid container>
          <Grid sx={{ padding: 5 }} item xs={12} sm={6} md={6}>
            <Button fullWidth sx={{ mt: 10, mb: 10, color: '#F5F5F5' }} variant="contained">
              Save
            </Button>
          </Grid>

          <Grid sx={{ padding: 5 }} item xs={12} sm={6} md={6}>
            <Button
              fullWidth
              sx={{
                mt: 10,
                mb: 10,
                backgroundColor: '#FF0000', // Your desired color value
                '&:hover': {
                  backgroundColor: '#CF0A0A', // Same color value as normal state
                },
              }}
              variant="contained"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
