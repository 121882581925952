import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TreeSelect, Select, Form, Input } from 'antd';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import BASE_URL from '../BASE_URL';

const AddAudio = () => {
  const navigate = useNavigate();
  // const [audioTitle, setAudioTitle] = useState('');
  const [categories, setCategories] = useState('');
  const [treeData, setTreeData] = useState([]);
  // const [link, setLink] = useState('');
  const [FetchedTags, setFetchedTags] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [size, setSize] = useState('middle');
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/audio/categories-with-children`);
        setCategoriesData(response.data); // Store the fetched data
        console.log(response.data);
      } catch (err) {
        setError('Error fetching categories with children'); // Handle errors
      }
    };

    const fetctchTags = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/audio/get-audio-tags-admin`);
        setFetchedTags(response.data); // Store the fetched data
        console.log('tags are', response.data);
      } catch (err) {
        setError('Error fetching categories with children'); // Handle errors
        console.log(err);
      }
    };

    fetchCategories(); // Trigger the data fetch
    fetctchTags();
  }, []);

  const transformToTreeData = (data) => {
    return data?.map((entry) => {
      const parent = {
        value: entry.parent._id, // ID is used for the value
        title: entry.parent.name, // The name is used for the title
        children: entry.children.map((child) => ({
          value: child._id, // Child ID
          title: child.name, // Child name
        })),
      };

      return parent;
    });
  };
  useEffect(() => {
    // Transform the data on component mount
    const transformedData = transformToTreeData(categoriesData);
    // console.log(categories);
    setTreeData(transformedData); // Update state with transformed data
  }, [categoriesData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}/api/audio/add-audio`, {
        // audioTitle,
        // categories,
        // link,
        // tags,
      });
      if (response.status === 201) {
        window.location.reload();
      }
    } catch (err) {
      alert('Error adding audio');
      console.error(err);
    }
  };

  const onChange = (newValue) => {
    console.log(newValue); // Logging the selected value(s)
    setCategories(newValue); // Update the value state
  };
  const options = FetchedTags?.map((tag) => ({
    value: tag?.name,
    label: tag?.name,
  }));
  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
    setSelectedTags(value);
  };

  const handlefinish = async (values) => {
    setIsLoading(true);
    console.log('antd values', values);
    const audioTitle = values?.audioTitle;
    const categories = values?.categories;
    const link = values?.link;
    const tags = values?.tags;

    try {
      const response = await axios.post(`${BASE_URL}/api/audio/add-audio`, {
        audioTitle,
        categories,
        link,
        tags,
      });

      if (response.status === 201) {
        window.location.reload();
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === 'Audio title must be unique'
      ) {
        alert('Audio title must be unique');
      } else {
        alert('Audio adding video');
      }
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <h1>Add Audio</h1>
      <Form
        name="wrap"
        onFinish={handlefinish}
        labelCol={{
          flex: '110px',
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        style={{
          width: '100%',
        }}
      >
        <Form.Item
          label="Audio Title"
          name="audioTitle"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder="Audio Title"
            // value={audioTitle}
            // defaultValue={videoTitle}
            // onChange={(e) => setVideoTitle(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label="Categories"
          name="categories"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TreeSelect
            showSearch
            style={{ width: '100%', cursor: 'pointer' }}
            value={categories} // The selected value(s)
            dropdownStyle={{ overflow: 'auto' }}
            placeholder="Please select"
            allowClear
            multiple // Allows selecting multiple options
            treeDefaultExpandAll
            onChange={onChange} // Event handler for changes
            treeData={treeData} // The transformed data
          />
        </Form.Item>
        <Form.Item
          label="Tags"
          name="tags"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            mode="tags"
            size={size}
            placeholder="Please select"
            // defaultValue={['a10', 'c12']}
            onChange={handleChange}
            style={{
              width: '100%',
            }}
            options={options}
          />
        </Form.Item>
        <Form.Item
          label="Audio Link"
          name="link"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder="Audio Link"
            // value={audioTitle}
            // defaultValue={videoTitle}
            // onChange={(e) => setVideoTitle(e.target.value)}
          />
        </Form.Item>

        <Grid container justifyContent={'center'}>
          <Grid item>
            <Form.Item label=" ">
              {/* <Button type="primary" htmlType="submit">
                  Submit
                </Button> */}
              <LoadingButton type="submit" variant="contained" loading={isLoading}>
                Submit
              </LoadingButton>
            </Form.Item>
          </Grid>
        </Grid>
        {/* <NumberInput /> */}
        {/* <FileSizeInput /> */}
      </Form>
    </>
  );
};

export default AddAudio;
