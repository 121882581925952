import React, { useState } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css'; // Import Quill's default "snow" theme

const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline'], // Basic formatting
  [{ list: 'ordered' }, { list: 'bullet' }], // List options
  [{ align: ['', 'center', 'right', 'justify'] }], // Text alignment options
  ['link'], // Add link button to the toolbar
];

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'align',
  'link', // Ensure "link" format is included
];

const Help = () => {
  const [content, setContent] = useState('');

  const handleChange = (value) => {
    setContent(value);
  };

  return (
    <>
    <div>
      <ReactQuill
        style={{ height: '100px' }}

        value={content}
        onChange={handleChange}
        modules={{ toolbar: toolbarOptions }}
        formats={formats} // Specify allowed formats, including links
      />
    </div>
    {/* <button onClick={()=>console.log(content)}>Click me</button> */}
    </>
  );
};

export default Help;

// import { Helmet } from 'react-helmet-async';
// // @mui
// import { Box, Card, CardContent, CardHeader, Container, Grid, Typography } from '@mui/material';

// // ----------------------------------------------------------------------

// export default function Help() {
//   const bull = (
//     <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(2.5)' }}>
//       •
//     </Box>
//   );

//   return (
//     <>
//       <Helmet>
//         <title> Help </title>
//       </Helmet>

//       <Container>
//         <Typography variant="h4" sx={{ mb: 5 }}>
//           Help
//         </Typography>
//         <Card>
//           <CardHeader>
//             <Typography variant="h4" sx={{ mb: 5 }}>
//               All points are written below
//             </Typography>
//           </CardHeader>
//           <CardContent>All points are written below</CardContent>
//           <Grid container>
//             <Grid item sx={{ pl: 5 }} xs={12} sm={12} md={12}>
//               <span style={{paddingRight:4}}>{bull}</span>
//               Cybersecurity is the practice of protecting systems, networks, and programs from digital attacks. These
//               cyberattacks are usually aimed at accessing, changing, or destroying sensitive information; extorting
//               money from users via ransomware; or interrupting normal business processes.
//             </Grid>
//             <Grid item sx={{ pl: 5 }} xs={12} sm={12} md={12}>
//               <span style={{paddingRight:4}}>{bull}</span>
//               Cybersecurity is the practice of protecting systems, networks, and programs from digital attacks. These
//               cyberattacks are usually aimed at accessing, changing, or destroying sensitive information; extorting
//               money from users via ransomware; or interrupting normal business processes.
//             </Grid>
//             <Grid item sx={{ pl: 5 }} xs={12} sm={12} md={12}>
//               <span style={{paddingRight:4}}>{bull}</span>
//               Cybersecurity is the practice of protecting systems, networks, and programs from digital attacks. These
//               cyberattacks are usually aimed at accessing, changing, or destroying sensitive information; extorting
//               money from users via ransomware; or interrupting normal business processes.
//             </Grid>
//             <Grid item sx={{ pl: 5 }} xs={12} sm={12} md={12}>
//               <span style={{paddingRight:4}}>{bull}</span>
//               Cybersecurity is the practice of protecting systems, networks, and programs from digital attacks. These
//               cyberattacks are usually aimed at accessing, changing, or destroying sensitive information; extorting
//               money from users via ransomware; or interrupting normal business processes.
//             </Grid>
//           </Grid>
//         </Card>
//       </Container>
//     </>
//   );
// }
