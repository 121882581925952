import { Helmet } from 'react-helmet-async';
import { useState } from 'react';

// @mui
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone';

// ----------------------------------------------------------------------

export default function CreateUser() {
  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  return (
    <>
      <Helmet>
        <title> User | Create User </title>
      </Helmet>
      <Container>
        <Typography variant="h4">Create user</Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Fill the required field indicated with *
        </Typography>
        <Card sx={{ mt: 3, height: 'auto' }}>
          <Typography variant="h5">User Information</Typography>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 150,
                width: 150,
                borderColor: '#2B2A4C',
                borderStyle: 'dotted', // Adding dotted border style
                borderWidth: 2, // Adjust border width as needed
                borderRadius: 100,
              }}
            >
              <CameraAltTwoToneIcon />
              Profile
            </div>
          </div>
          <Grid container>
            <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={6}>
              <Typography sx={{ marginLeft: 3 }} variant="h7">
                First Name <span style={{ color: 'red' }}>*</span>{' '}
              </Typography>
              <br />
              <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                <OutlinedInput id="outlined-adornment-amount" />
              </FormControl>
            </Grid>

            <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={6}>
              <Typography sx={{ marginLeft: 3 }} variant="h7">
                Last Name <span style={{ color: 'red' }}>*</span>{' '}
              </Typography>
              <br />
              <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                <OutlinedInput id="outlined-adornment-amount" />
              </FormControl>
            </Grid>

            <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={6}>
              <Typography sx={{ marginLeft: 3 }} variant="h7">
                Contact no. <span style={{ color: 'red' }}>*</span>{' '}
              </Typography>
              <br />
              <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                <OutlinedInput id="outlined-adornment-amount" />
              </FormControl>
            </Grid>

            <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={6}>
              <Typography sx={{ marginLeft: 3 }} variant="h7">
                Email <span style={{ color: 'red' }}>*</span>{' '}
              </Typography>
              <br />
              <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                <OutlinedInput id="outlined-adornment-amount" />
              </FormControl>
            </Grid>
            <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={6}>
              <Typography sx={{ marginLeft: 3 }} variant="h7">
                Address <span style={{ color: 'red' }}>*</span>{' '}
              </Typography>
              <br />
              <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                <OutlinedInput id="outlined-adornment-amount" />
              </FormControl>
            </Grid>                 
            <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={6}>
              <Typography sx={{ marginLeft: 3 }} variant="h7">
                Role <span style={{ color: 'red' }}>*</span>{' '}
              </Typography>
              <br />
              <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                <OutlinedInput id="outlined-adornment-amount" />
              </FormControl>
            </Grid>
          </Grid>
          <Button fullWidth sx={{mt:10,mb:10}} variant='contained'>Submit</Button>
        </Card>
      </Container>
    </>
  );
}
