import React from 'react';
import { useLocation } from 'react-router-dom';

export default function ContactDetails() {
  const location = useLocation();
  const { contact } = location.state;
  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Contact Details</h1>
      <p style={{ textAlign: 'center' }}>
        Name:{contact?.name}
        <br />
        Email: {contact?.email}
        <br />
        Phone: {contact?.mobileNumber}
        <br />
        Message: {contact?.messagebody}
        <br />
      </p>
    </div>
  );
}
