import axios from 'axios';
import BASE_URL from '../BASE_URL';


export const addModel = async (modelData) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/model/add-model`, modelData);
    return response.data;
  } catch (error) {
    console.error("Error adding model:", error);
    throw error;
  }
};

// Get a single model by ID
export const getModelById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/model/get-model/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching model:", error);
    throw error;
  }
};

// Update a model by ID
export const updateModelById = async (id, updatedData) => {
  try {
    const response = await axios.put(`${BASE_URL}/api/model/update-model/${id}`, updatedData);
    return response.data;
  } catch (error) {
    console.error("Error updating model:", error);
    throw error;
  }
};

// Delete a model by ID
export const deleteModelById = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/api/model/delete-model/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting model:", error);
    throw error;
  }
};

// Get all models with pagination
export const getModels = async (page, limit) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/model/get-models`, {
      params: { page, limit }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching models:", error);
    throw error;
  }
};















// Add a new tag
export const addTag = async (tagName) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/model/add-tag`, {
      name: tagName,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error adding tag');
  }
};

// Get all tags
export const getTags = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/model/get-tags`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error fetching tags');
  }
};

// Get a single tag by ID
export const getTagById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/get-tag/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error fetching tag');
  }
};

// Update a tag by ID
export const updateTag = async (id, tagName) => {
  try {
    const response = await axios.put(`${BASE_URL}/update-tag/${id}`, {
      name: tagName,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error updating tag');
  }
};

// Delete a tag by ID
export const deleteTag = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/api/model/delete-tag/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error deleting tag');
  }
};

export const addFormat = async (name) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/model/add-format`, { name });
    return response.data;
  } catch (error) {
    console.error('Error adding format:', error);
    throw error;
  }
};

// Get all formats
export const getFormats = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/model/get-formats`);
    return response.data;
  } catch (error) {
    console.error('Error fetching formats:', error);
    throw error;
  }
};

// Get a single format by ID
export const getFormatById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/model/get-format/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching format:', error);
    throw error;
  }
};

// Update a format by ID
export const updateFormatById = async (id, name) => {
  try {
    const response = await axios.put(`${BASE_URL}/api/model/update-format/${id}`, { name });
    return response.data;
  } catch (error) {
    console.error('Error updating format:', error);
    throw error;
  }
};

// Delete a format by ID
export const deleteFormatById = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/api/model/delete-format/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting format:', error);
    throw error;
  }
};
