import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import axios from 'axios';
import { searchVideosByTitle } from '../../Services/videoServices';
import BASE_URL from '../../BASE_URL';

export default function SearchByTitle() {
  const { paramspage } = useParams();
  const { paramstitle } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState(paramstitle);
  const [videos, setVideos] = useState([]);
  const [page, setPage] = useState(paramspage);
  const [totalPages, setTotalPages] = useState(0);
  const [totalVideos, setTotalVideos] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSearch = async (limit = 100) => {
    navigate(`/dashboard/videos-list/${title}/1`);
    setLoading(true);
    setError('');
    try {
      const data = await searchVideosByTitle(title, page, limit);
      setVideos(data.videos);
      setTotalPages(data.totalPages);
      setTotalVideos(data.totalVideos);
    } catch (err) {
      setError('Error searching videos');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (newPage) => {
    setPage(newPage);
    await handleSearch();
  };
  const handleVideo = (row) => {
    navigate('/dashboard/video-details', {
      state: {
        // ...values
        video: row,
      },
    });
  };
  const handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete this video?');

      if (confirmDelete) {
        const response = await axios.delete(`${BASE_URL}/api/video/delete-video/${id}`);

        if (response.status === 200) {
          // alert('Video deleted successfully');
          setVideos(videos.filter((video) => video._id !== id));
        } else {
          console.error('Failed to delete video');
        }
      }
    } catch (error) {
      console.error('Error deleting video:', error);
    }
  };
  const handleUpdate = (row) => {
    navigate('/dashboard/update-video', {
      state: {
        // ...values
        video: row,
      },
    });
  };
  const handleCilck = () => {
    navigate('/dashboard/videos');
  };
  useEffect(() => {
    handleSearch();
  }, [paramstitle]);
  return (
    <div>
      <h1>Videos List</h1>
      <form onSubmit={handleSearch}>
        <input
          required
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter video title"
        />
        <button type="submit">Search</button>
      </form>
      <Button variant="contained" sx={{ float: 'right' }} onClick={() => handleCilck()}>
        Add video
      </Button>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}

      {/* <div>
        {videos.map((video) => (
          <div key={video._id}>
            <h2>{video.videoTitle}</h2>
            <p>{video.description}</p>
          </div>
        ))}
      </div> */}
      <TableContainer style={{ overflow: 'auto' }} component={Paper}>
        <Table sx={{ minWidth: 650, overflow: 'auto' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Video Title</TableCell>
              <TableCell align="right">Resolution</TableCell>
              <TableCell align="right">Frame Rate</TableCell>
              <TableCell align="right">Format</TableCell>
              <TableCell align="right">Action</TableCell>
              <TableCell align="right">Delete Video</TableCell>
              <TableCell align="right">Update Video</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {videos?.map((row) => (
              <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.videoTitle}
                </TableCell>
                <TableCell align="right">{row.resolution}</TableCell>
                <TableCell align="right">{row.framerate}</TableCell>
                <TableCell align="right">{row.format}</TableCell>
                {/* <TableCell align="right">{row.protein}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell> */}
                <TableCell align="right">
                  <Button onClick={() => handleVideo(row)}>View</Button>
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => handleDelete(row?._id)}>Delete</Button>
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => handleUpdate(row)}>Update</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <div>
        {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
          <button key={pageNumber} onClick={() => handlePageChange(pageNumber)} disabled={page === pageNumber}>
            {pageNumber}
          </button>
        ))}
      </div> */}

      <p>Total Videos: {totalVideos}</p>
    </div>
  );
}
