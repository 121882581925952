// component
import { Icon } from '@iconify/react';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1, color: 'white' }} />
);

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'Tenant List',
  //   path: '/dashboard/tenant_list',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'Create Tenants',
  //   path: '/dashboard/create_tenants',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'Licenses',
  //   path: '/dashboard/licenses',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'Creat User',
  //   path: '/dashboard/create_user',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'User Permission',
  //   path: '/dashboard/user_permission',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'Alert and notification',
  //   path: '/dashboard/alerts',
  //   icon: icon('ic_user'),
  // },
  {
    title: 'Categories',
    path: '/dashboard/category',
    icon: icon('ic_user'),
  },
  {
    title: 'videos',
    path: '/dashboard/videos-list/1',
    icon: icon('ic_user'),
  },
  {
    title: 'Audio Category',
    path: '/dashboard/audio-category',
    icon: icon('ic_user'),
  },
  {
    title: 'Audio Tags',
    path: '/dashboard/audio-tags',
    icon: icon('ic_user'),
  },
  {
    title: 'Audio',
    path: '/dashboard/audio-list/1',
    icon: icon('ic_user'),
  },
  {
    title: 'Sky Boxes',
    path: '/dashboard/sky-box',
    icon: icon('ic_blog'),
  },

  {
    title: '3D Categories',
    path: '/dashboard/model-category',
    icon: icon('ic_blog'),
  },

  {
    title: '3D Tags',
    path: '/dashboard/model-tags',
    icon: icon('ic_blog'),
  },
  {
    title: '3D Formats',
    path: '/dashboard/model-formats',
    icon: icon('ic_blog'),
  },
  {
    title: '3D Models',
    path: '/dashboard/model-list',
    icon: icon('ic_blog'),
  },
  {
    title: 'Blog',
    path: '/dashboard/blog-list',
    icon: icon('ic_blog'),
  },
  {
    title: 'Category Card',
    path: '/dashboard/card-list',
    icon: icon('ic_blog'),
  },
  {
    title: 'Customer Quries',
    path: '/dashboard/customers-queries',
    icon: icon('ic_blog'),
  },
  {
    title: 'Messages',
    path: '/dashboard/contact-list',
    icon: icon('ic_blog'),
  },
  
 
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  //   {
  //   title: 'securities',
  //   path: 'securities',
  //   icon: icon('ic_blog'),
  // },

  // {
  //   title: 'Help',
  //   path: 'help',
  //   icon: icon('ic_blog'),
  // },

  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
