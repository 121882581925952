import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  // Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  // Typography,
} from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { DataGrid } from '@mui/x-data-grid';
import StepperInput from '../../../components/Stepper/Stepper';

const columns = [
  // { field: 'id', headerName: 'ID', width: 300 },
  { field: 'modules', headerName: 'Modules', width: 300 },
  {
    field: 'read',
    headerName: 'Select',
    width: 130,
    renderCell: (params) => <input type="checkbox" checked={params.value} />,
  },
  // {
  //   field: 'write',
  //   headerName: 'Write',
  //   width: 130,
  //   renderCell: (params) => <input type="checkbox" checked={params.value} />,
  // },
  // {
  //   field: 'eidt',
  //   headerName: 'Edit',
  //   // type: 'number',
  //   width: 130,
  //   renderCell: (params) => <input type="checkbox" checked={params.value} />,
  // },
  // {
  //   field: 'delete',
  //   headerName: 'Delete',
  //   // type: 'number',
  //   width: 130,
  //   renderCell: (params) => <input type="checkbox" checked={params.value} />,
  // },
  //   {
  //     field: 'fullName',
  //     headerName: 'Full name',
  //     description: 'This column has a value getter and is not sortable.',
  //     sortable: false,
  //     width: 160,
  //     valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  //   },
];

const rows = [
  { id: 1, modules: 'Dashboard', lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, modules: 'Create Tenant', lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, modules: 'Tenant License List', lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, modules: 'Tenant List', lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, modules: 'User Permission', lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, modules: 'Alerts and Notifications', lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, modules: 'Reports', lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, modules: 'Security', lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, modules: 'Help', lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  { id: 9, modules: 'Settings', lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNext = () => {
    // Increment the value to go to the next tab
    setValue((prevValue) => (prevValue < 2 ? prevValue + 1 : prevValue));
  };

  const handlePrev = () => {
    // Decrement the value to go to the previous tab
    setValue((prevValue) => (prevValue > 0 ? prevValue - 1 : prevValue));
  };

  const steps = ['Subscription Details', 'Subscription Duration', 'Confirm Creation'];

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const stepHandleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const [LongParts, setLongParts] = React.useState(0);
  const handleLongParts = (value) => {
    setLongParts(value);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Profile Details" {...a11yProps(0)} />
          <Tab label="License Details" {...a11yProps(1)} />
          <Tab label="More Details" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Container>
          <Typography variant="h4">Create New Tenants</Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Fill the required field indicated with *
          </Typography>
          <Card sx={{ mt: 3, height: 'auto' }}>
            <Typography variant="h5">Business Information</Typography>
            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 150,
                width: 150,
                borderColor: '#2B2A4C',
                borderStyle: 'dotted', // Adding dotted border style
                borderWidth: 2, // Adjust border width as needed
                borderRadius: 100,
              }}
            >
              <CameraAltTwoToneIcon />
              Profile
            </div>
          </div> */}
            <Grid container>
              <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={6}>
                <Typography sx={{ marginLeft: 3 }} variant="h7">
                  Business Name <span style={{ color: 'red' }}>*</span>{' '}
                </Typography>
                <br />
                <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                  <OutlinedInput id="outlined-adornment-amount" />
                </FormControl>
              </Grid>

              <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={6}>
                <Typography sx={{ marginLeft: 3 }} variant="h7">
                  Client Name <span style={{ color: 'red' }}>*</span>{' '}
                </Typography>
                <br />
                <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                  <OutlinedInput id="outlined-adornment-amount" />
                </FormControl>
              </Grid>

              <Grid sx={{ marginTop: 5 }} item xs={12} sm={12} md={12}>
                <Typography sx={{ marginLeft: 3 }} variant="h5">
                  Business Logo
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 300,
                    width: '100%',
                    borderColor: '#2B2A4C',
                    borderStyle: 'dotted', // Adding dotted border style
                    borderWidth: 2, // Adjust border width as needed
                    borderRadius: 5,
                    backgroundColor: '#DFCCFB',
                  }}
                >
                  <Grid container>
                    <Grid sx={{ marginTop: 5, pl: 30 }} item xs={12} sm={6} md={6}>
                      <FileCopyIcon style={{ fontSize: 100 }} />
                    </Grid>
                    {/* <div style={{width:'5px', height:200, backgroundColor:'#3F1D38'}} >.</div> */}
                    <Grid sx={{ marginTop: 5, pl: 10 }} item xs={12} sm={6} md={6}>
                      Add logo Image
                      <br />
                      <Button sx={{ width: '200px' }} variant="contained">
                        Browse
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={12} md={12}>
                  <Typography sx={{ marginLeft: 3 }} variant="h5">
                    Address
                  </Typography>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Site Name <span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Site Code <span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Business Segment <span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Site Area <span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Site State <span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Site City <span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Site Region <span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Site Country <span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    GPS Location <span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
              </>
              <>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={12} md={12}>
                  <Typography sx={{ marginLeft: 3 }} variant="h5">
                    Contact Information
                  </Typography>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Primary Email <span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Primary Phone no. <span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Reception Phone no. (if any)
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Secondary Email
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Secondary Phone no.
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
              </>

              <>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={12} md={12}>
                  <Typography sx={{ marginLeft: 3 }} variant="h5">
                    Social Links
                  </Typography>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Website Link <span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Linkedin Link
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Facebook Link
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Instagram Links
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item xs={12} sm={6} md={4}>
                  <Typography sx={{ marginLeft: 3 }} variant="h7">
                    Twitter Link
                  </Typography>
                  <br />
                  <FormControl fullWidth sx={{ m: 1, paddingRight: 5 }}>
                    <OutlinedInput id="outlined-adornment-amount" />
                  </FormControl>
                </Grid>
              </>

              <Grid sx={{ marginTop: 5 }} item xs={12} sm={12} md={12}>
                <Typography sx={{ marginLeft: 3, p: 1 }} variant="h5">
                  Documents
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Legal Document foe business registration
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 300,
                    width: '100%',
                    borderColor: '#2B2A4C',
                    borderStyle: 'dotted', // Adding dotted border style
                    borderWidth: 2, // Adjust border width as needed
                    borderRadius: 5,
                    backgroundColor: '#DFCCFB',
                  }}
                >
                  <Grid container>
                    <Grid sx={{ marginTop: 5, pl: 30 }} item xs={12} sm={6} md={6}>
                      <FileCopyIcon style={{ fontSize: 100 }} />
                    </Grid>
                    {/* <div style={{width:'5px', height:200, backgroundColor:'#3F1D38'}} >.</div> */}
                    <Grid sx={{ marginTop: 5, pl: 10 }} item xs={12} sm={6} md={6}>
                      Add registration proof
                      <br />
                      <Button sx={{ width: '200px' }} variant="contained">
                        Browse
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid sx={{ marginTop: 5 }} item xs={12} sm={12} md={12}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Letter Head
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 300,
                    width: '100%',
                    borderColor: '#2B2A4C',
                    borderStyle: 'dotted', // Adding dotted border style
                    borderWidth: 2, // Adjust border width as needed
                    borderRadius: 5,
                    backgroundColor: '#DFCCFB',
                  }}
                >
                  <Grid container>
                    <Grid sx={{ marginTop: 5, pl: 30 }} item xs={12} sm={6} md={6}>
                      <FileCopyIcon style={{ fontSize: 100 }} />
                    </Grid>
                    {/* <div style={{width:'5px', height:200, backgroundColor:'#3F1D38'}} >.</div> */}
                    <Grid sx={{ marginTop: 5, pl: 10 }} item xs={12} sm={6} md={6}>
                      Add letter head
                      <br />
                      <Button sx={{ width: '200px' }} variant="contained">
                        Browse
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            {/* <Button fullWidth sx={{ mt: 10, mb: 10 }} variant="contained">
              Save and Next
            </Button> */}
          </Card>
        </Container>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div style={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            // pageSizeOptions={[5, 15]}
            // checkboxSelection
          />
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = <Typography variant="caption">Optional</Typography>;
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </>
          ) : (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {activeStep === 0 ? (
                  <>
                    <Grid container>
                      <Grid sx={{ marginTop: 5 }} item xs={12} sm={12} md={12}>
                        <Typography variant="h4">Tenant Admin</Typography>
                      </Grid>

                      <Grid sx={{ marginTop: 5 }} item xs={12} sm={12} md={6}>
                        {/* <p style={{ paddingRight: 3 }}>No. of tenant Admin? </p> */}
                        <StepperInput
                          label="No of total Admins"
                          value={LongParts}
                          onChange={handleLongParts}
                          min={0}
                          max={3}
                        />
                      </Grid>
                      <Grid sx={{ marginTop: 5 }} item xs={12} sm={12} md={6}>
                        <Card sx={{ backgroundColor: '#3B63A9' }}>
                          <GridViewIcon />
                        </Card>
                      </Grid>
                    </Grid>
                  </>
                ) : activeStep === 1 ? (
                  <>2</>
                ) : activeStep === 2 ? (
                  <>3</>
                ) : (
                  ''
                )}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}

                <Button onClick={stepHandleNext}>{activeStep === steps.length - 1 ? 'Finish' : 'Next'}</Button>
              </Box>
            </>
          )}
        </Box>
      </CustomTabPanel>
      <Button onClick={handlePrev} variant="contained" disabled={value === 0}>
        Previous
      </Button>
      <Button onClick={handleNext} sx={{ float: 'right' }} variant="contained" disabled={value === 2}>
        Next
      </Button>
    </Box>
  );
}
