import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
// import audio from './Coorporate buildings.webm'

const AudioDetails = () => {
  const location = useLocation();
  const { audio } = location.state;
  console.log(audio);
  return (
    <div style={{ marginTop: '100px' }}>
      <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>{audio?.audioTitle}</h1>

      {/* <div dangerouslySetInnerHTML={{ __html: audio?.description }} /> */}

      <div style={{ textAlign: 'center', margin: '30px' }}>
        Audio Link: {audio?.link} <br />
        Audio Tags: {audio?.tags?.map((singleAudio)=>(<div key={singleAudio?.index}>
          {singleAudio},
        </div>))}<br />
        {/* Frame Rate: {audio?.framerate} <br />
        Format:{audio?.format} <br />
        resolution:{audio?.resolution} <br />
        timeDuration:{audio?.timeDuration} <br />
        fileSize:{audio?.fileSize} <br /> */}
        {/* audio Link: {audio?.audiolink} <br /> */}
        {/* Preview Link: {audio?.preivewfile} <br /> */}
        {/* {downloadProgress > 0 && <p>Download Progress: {downloadProgress.toFixed(2)}%</p>} */}
      </div>

      {/* description:{audio?.description} <br /> */}
    </div>
  );
};

export default AudioDetails;
