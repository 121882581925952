import React, { useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Cascader, InputNumber, Select, Space } from 'antd';

const { Option } = Select;

const selectAfterOptions = [
  { value: 'MB', label: 'MB' },
  { value: 'GB', label: 'GB' },
];

const NumberInput = () => {
  const [unit, setUnit] = useState('MB');
  const [value, setValue] = useState(0);

  const handleUnitChange = (selectedUnit) => {
    setUnit(selectedUnit);
  };

  const handleValueChange = (inputValue) => {
    setValue(inputValue);
  };

  const handleSubmit = () => {
    const combinedValue = `${value}${unit}`;
    console.log('Combined value:', combinedValue);
    // Use combinedValue for further processing, like submitting the form
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <InputNumber
        addonAfter={
          <Select defaultValue={unit} onChange={handleUnitChange} style={{ width: 70 }}>
            {selectAfterOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        }
        defaultValue={value}
        onChange={handleValueChange}
        size="large"
        style={{ width: '100%' }}
      />
      <button onClick={handleSubmit}>Submit</button>
    </Space>
  );
};

export default NumberInput;
