import axios from 'axios';
import BASE_URL from '../BASE_URL'; // Make sure to define your BASE_URL appropriately

// Add Category Card
export const addCategoryCard = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/category/add-category-card`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding category card:', error);
    throw error;
  }
};

// Update Category Card
export const updateCategoryCard = async (id, formData) => {
  try {
    const response = await axios.put(`${BASE_URL}/api/category/update-category-card/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating category card:', error);
    throw error;
  }
};

// Remove Category Card
export const removeCategoryCard = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/api/category/remove-category-card/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error removing category card:', error);
    throw error;
  }
};

// Get All Category Cards
export const getCategoryCards = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/category/get-category-cards`);
    return response.data;
  } catch (error) {
    console.error('Error fetching category cards:', error);
    throw error;
  }
};

// Get Category Card by ID
export const getCategoryCardById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/category/get-category-card/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching category card:', error);
    throw error;
  }
};
