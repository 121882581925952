import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// @mui
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import BarChartIcon from '@mui/icons-material/BarChart';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, Card, CardActions, CardContent, Container, Grid, Stack, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function HomePage() {
  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  return (
    <>
      <Helmet>
        <title> Home </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hello, Awais Ali
        </Typography>
        <Grid container>
          <Grid container spacing={2} md={12}>
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ minWidth: 200, minHeight: 150, display: 'flex', flexDirection: 'column' }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Create Tenants
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <AddCircleIcon sx={{ color: '#FFBB5C', fontSize: '4rem' }} />
                  </div>

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Add new client in system by clicking the button down
                  </Typography>
                </CardContent>
                <Button variant="contained" sx={{ minWidth: 200, marginTop: 'auto', backgroundColor: '#3B63A9' }}>
                  view more
                  <ArrowRightAltIcon />
                </Button>
              </Card>
              {/* <AppWidgetSummary
                title="Total tenants"
                total={714000}
                // icon={'ant-design:android-filled'}
              /> */}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ minWidth: 200, minHeight: 150, display: 'flex', flexDirection: 'column' }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Tenants list
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <PeopleIcon sx={{ color: '#1A5D1A', fontSize: '4rem' }} />
                  </div>

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Add new client in system by clicking the button down
                  </Typography>
                </CardContent>
                <Button variant="contained" sx={{ minWidth: 200, marginTop: 'auto', backgroundColor: '#3B63A9' }}>
                  view more
                  <ArrowRightAltIcon />
                </Button>
              </Card>
              {/* <AppWidgetSummary
                title="Total tenants"
                total={714000}
                // icon={'ant-design:android-filled'}
              /> */}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ minWidth: 200, minHeight: 150, display: 'flex', flexDirection: 'column' }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Create User
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <PersonAddIcon sx={{ color: '#068FFF', fontSize: '4rem' }} />
                  </div>

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Add new client in system by clicking the button down
                  </Typography>
                </CardContent>
                <Button variant="contained" sx={{ minWidth: 200, marginTop: 'auto', backgroundColor: '#3B63A9' }}>
                  view more
                  <ArrowRightAltIcon />
                </Button>
              </Card>
              {/* <AppWidgetSummary
                title="Total tenants"
                total={714000}
                // icon={'ant-design:android-filled'}
              /> */}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ minWidth: 200, minHeight: 150, display: 'flex', flexDirection: 'column' }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Analytics
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <PeopleIcon sx={{ color: '#1A5D1A', fontSize: '4rem' }} />
                  </div>

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Add new client in system by clicking the button down
                  </Typography>
                </CardContent>
                <Button variant="contained" sx={{ minWidth: 200, marginTop: 'auto', backgroundColor: '#3B63A9' }}>
                  view more
                  <ArrowRightAltIcon />
                </Button>
              </Card>
              {/* <AppWidgetSummary
                title="Total tenants"
                total={714000}
                // icon={'ant-design:android-filled'}
              /> */}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ minWidth: 200, minHeight: 150, display: 'flex', flexDirection: 'column' }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Analytics
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <BarChartIcon sx={{ color: '#4E4FEB', fontSize: '4rem' }} />
                  </div>

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Add new client in system by clicking the button down
                  </Typography>
                </CardContent>
                <Button variant="contained" sx={{ minWidth: 200, marginTop: 'auto', backgroundColor: '#3B63A9' }}>
                  view more
                  <ArrowRightAltIcon />
                </Button>
              </Card>
              {/* <AppWidgetSummary
                title="Total tenants"
                total={714000}
                // icon={'ant-design:android-filled'}
              /> */}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ minWidth: 200, minHeight: 150, display: 'flex', flexDirection: 'column' }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    License list
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <FeaturedPlayListIcon sx={{ color: '#FFBB5C', fontSize: '4rem' }} />
                  </div>

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Add new client in system by clicking the button down
                  </Typography>
                </CardContent>
                <Button variant="contained" sx={{ minWidth: 200, marginTop: 'auto', backgroundColor: '#3B63A9' }}>
                  view more
                  <ArrowRightAltIcon />
                </Button>
              </Card>
              {/* <AppWidgetSummary
                title="Total tenants"
                total={714000}
                // icon={'ant-design:android-filled'}
              /> */}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ minWidth: 200, minHeight: 150, display: 'flex', flexDirection: 'column' }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Alerts
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ErrorOutlineIcon sx={{ color: '#BB2525', fontSize: '4rem' }} />
                  </div>

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Add new client in system by clicking the button down
                  </Typography>
                </CardContent>
                <Button variant="contained" sx={{ minWidth: 200, marginTop: 'auto', backgroundColor: '#3B63A9' }}>
                  view more
                  <ArrowRightAltIcon />
                </Button>
              </Card>
              {/* <AppWidgetSummary
                title="Total tenants"
                total={714000}
                // icon={'ant-design:android-filled'}
              /> */}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ minWidth: 200, minHeight: 150, display: 'flex', flexDirection: 'column' }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Data Usage
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <DataUsageIcon sx={{ color: '#3C486B', fontSize: '4rem' }} />
                  </div>

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Add new client in system by clicking the button down
                  </Typography>
                </CardContent>
                <Button variant="contained" sx={{ minWidth: 200, marginTop: 'auto', backgroundColor: '#3B63A9' }}>
                  view more
                  <ArrowRightAltIcon />
                </Button>
              </Card>
              {/* <AppWidgetSummary
                title="Total tenants"
                total={714000}
                // icon={'ant-design:android-filled'}
              /> */}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ minWidth: 200, minHeight: 150, display: 'flex', flexDirection: 'column' }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Security
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <LocalPoliceIcon sx={{ color: '#0079FF', fontSize: '4rem' }} />
                  </div>

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Add new client in system by clicking the button down
                  </Typography>
                </CardContent>
                <Button variant="contained" sx={{ minWidth: 200, marginTop: 'auto', backgroundColor: '#3B63A9' }}>
                  view more
                  <ArrowRightAltIcon />
                </Button>
              </Card>
              {/* <AppWidgetSummary
                title="Total tenants"
                total={714000}
                // icon={'ant-design:android-filled'}
              /> */}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ minWidth: 200, minHeight: 150, display: 'flex', flexDirection: 'column' }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Help
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <HelpOutlineIcon sx={{ color: '#F29727', fontSize: '4rem' }} />
                  </div>

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Add new client in system by clicking the button down
                  </Typography>
                </CardContent>
                <Button variant="contained" sx={{ minWidth: 200, marginTop: 'auto', backgroundColor: '#3B63A9' }}>
                  view more
                  <ArrowRightAltIcon />
                </Button>
              </Card>
              {/* <AppWidgetSummary
                title="Total tenants"
                total={714000}
                // icon={'ant-design:android-filled'}
              /> */}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
