import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

// @mui
import { LoadingButton } from '@mui/lab';
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  // Select,
  TextField,
  Typography,
} from '@mui/material';
// import { TreeSelect } from 'antd';
import axios from 'axios';
import { Button, Form, Input, TreeSelect, Select, InputNumber, TimePicker, message } from 'antd';
// import React, { useState } from 'react';
// import {} from 'antd';
import dayjs from 'dayjs';
import ReactQuill from 'react-quill';
import BASE_URL from '../BASE_URL';
import Help from './Help';
import NumberInput from './NumberInput';
import FileSizeInput from './fileSizeInput';
import Test from '../components/Test/Test';

import 'react-quill/dist/quill.snow.css';

// ----------------------------------------------------------------------

const { Option } = Select;
const selectTimeUnit = [
  { value: 'sec', label: 'sec' },
  { value: 'min', label: 'min' },
];
const selectSizeUnit = [
  { value: 'MB', label: 'MB' },
  { value: 'GB', label: 'GB' },
];
const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline'], // Basic formatting
  [{ list: 'ordered' }, { list: 'bullet' }], // List options
  [{ align: ['', 'center', 'right', 'justify'] }], // Text alignment options
  ['link'], // Add link button to the toolbar
];

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'align',
  'link', // Ensure "link" format is included
];
export default function Reports() {
  const [openFilter, setOpenFilter] = useState(false);
  const [content, setContent] = useState('');
  const navigate = useNavigate();
  const handleChange = (value) => {
    setContent(value);
  };
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const [number, setNumber] = useState(0);

  const [videoTitle, setVideoTitle] = useState('');
  const [categories, setCategories] = useState();
  // const [fileSize, setFileSize] = useState('');
  // const [timeDuration, setTimeDuration] = useState('');
  // const [description, setDescription] = useState('');
  const [videolink, setVideoLink] = useState('');
  const [resolution, setResolution] = useState('1080p');
  const [framerate, setFramerate] = useState('29.97fps');
  const [format, setFormat] = useState('h.264');
  const [previewFile, setPreviewFile] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [sizeUnit, setSizeUnit] = useState('MB');
  const [sizeValue, setSizeValue] = useState(0);
  const [durationUnit, setDurationUnit] = useState('sec');
  const [durationValue, setDurationValue] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  // const handleSubmit = async (e) => {
  //   const newVideo = {
  //     videoTitle,
  //     categories, // Split categories into an array
  //     fileSize: `${sizeValue}${sizeUnit}`,
  //     timeDuration: `${durationValue}${durationUnit}`,
  //     description: content,
  //     link,
  //     resolution,
  //     framerate,
  //     format,
  //     preivewfile: previewFile,
  //   };
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post('http://localhost:5000/api/video/add-video', newVideo);
  //     alert('Video added successfully');
  //   } catch (err) {
  //     alert('Error adding video');
  //   } finally {
  //     setIsLoading(false); // End loading
  //   }
  // };

  const handleSubmit = async (e) => {
    // e.preventDefault(); // Prevent default form submission

    const formData = new FormData();
    formData.append('videoTitle', videoTitle);
    formData.append('categories', categories); // Convert array to string
    formData.append('fileSize', `${sizeValue}${sizeUnit}`);
    formData.append('timeDuration', durationValue);
    formData.append('description', content);
    formData.append('videolink', videolink);
    formData.append('resolution', resolution);
    formData.append('framerate', framerate);
    formData.append('format', format);
    formData.append('preivewfile', previewFile);
    formData.append('thumbnail', thumbnail);

    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/api/video/add-video`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 201) {
        window.location.reload();
      }
      // if (response.status === 201) {
      //   setVideoTitle('');
      //   setCategories(null);
      //   setVideoLink('');
      //   setPreviewFile('');
      //   setSizeUnit('MB');
      //   setSizeValue(0);
      //   setDurationUnit('sec');
      //   setDurationValue(0);
      //   setThumbnail(null);
      // }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === 'Video title must be unique'
      ) {
        alert('Video title must be unique');
      } else {
        alert('Error adding video');
      }
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const [videos, setVideos] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/video/get-videos`);
        setVideos(response.data);
      } catch (err) {
        setError('Error fetching videos');
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideos();
  }, []);

  const [categoriesData, setCategoriesData] = useState([]);
  // const [categories, setCategories] = useState([]);
  // const [isLoading, setIsLoading] = useState(true)
  // const [error, setError] = useState(null);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/category/categories-with-children`);
        setCategoriesData(response.data); // Store the fetched data
        console.log(response.data);
      } catch (err) {
        setError('Error fetching categories with children'); // Handle errors
      } finally {
        setIsLoading(false); // Loading is complete
      }
    };

    fetchCategories(); // Trigger the data fetch
  }, []);

  const transformToTreeData = (data) => {
    return data?.map((entry) => {
      const parent = {
        value: entry.parent._id, // ID is used for the value
        title: entry.parent.name, // The name is used for the title
        children: entry.children.map((child) => ({
          value: child._id, // Child ID
          title: child.name, // Child name
        })),
      };

      return parent;
    });
  };
  // const [value, setValue] = useState();
  const [treeData, setTreeData] = useState([]); // Initialize with empty array

  useEffect(() => {
    // Transform the data on component mount
    const transformedData = transformToTreeData(categoriesData);
    // console.log(categories);
    setTreeData(transformedData); // Update state with transformed data
  }, [categoriesData]); // Empty dependency array to ensure this only runs once

  const onChange = (newValue) => {
    console.log(newValue); // Logging the selected value(s)
    setCategories(newValue); // Update the value state
  };

  const handleFileChange = (event) => {
    setThumbnail(event.target.files[0]);
  };
  const onFinish = (values) => {
    console.log('Received values:', values);
    // Here you can submit the form values to your backend or perform any other action
  };
  const handleResolution = (value) => {
    console.log('Selected resolution:', value);
    setResolution(value);
  };
  const handleFrameRate = (value) => {
    console.log('Selected Frame Rate:', value);
    setFramerate(value);
  };
  const handleFormat = (value) => {
    console.log('Selected Format:', value);
    setFormat(value);
  };

  const handleSizeUnitChange = (selectedUnit) => {
    setSizeUnit(selectedUnit);
  };

  const handleSizeValueChange = (inputValue) => {
    setSizeValue(inputValue);
  };

  const handleDurationUnitChange = (selectedUnit) => {
    setDurationUnit(selectedUnit);
  };

  const handleDurationValueChange = (inputValue) => {
    setDurationValue(inputValue);
  };

  const [pickerValue, setPickerValue] = useState(null);
  const onDurationChange = (time) => {
    setPickerValue(time);
  };

  const onOk = (selectedTime) => {
    if (selectedTime) {
      // Convert the selected time into seconds
      const seconds = selectedTime.hour() * 3600 + selectedTime.minute() * 60 + selectedTime.second();
      setDurationValue(seconds);
      message.success(`Time selected: ${seconds} seconds`);
    } else {
      message.error('Please select a time');
    }
  };

  return (
    <>
      <Helmet>
        <title> Add Video </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Videos data list
        </Typography>

        <Form
          name="wrap"
          onFinish={handleSubmit}
          labelCol={{
            flex: '110px',
          }}
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 1,
          }}
          colon={false}
          style={{
            width: '100%',
          }}
        >
          <Form.Item
            label="Video Title"
            name="Video Title"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              placeholder="Video Title"
              value={videoTitle}
              defaultValue={videoTitle}
              onChange={(e) => setVideoTitle(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="Video link"
            name="Video link"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Video link" value={videolink} onChange={(e) => setVideoLink(e.target.value)} />
          </Form.Item>

          <Form.Item
            label="Resolution"
            name="Resolution"
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
          >
            <Select placeholder="Resolution" value={resolution} defaultValue={'1080p'} onChange={handleResolution}>
              <Select.Option value={'720p'}>720p</Select.Option>
              <Select.Option value={'1080p'}>1080p</Select.Option>
              <Select.Option value={'2k'}>2k</Select.Option>
              <Select.Option value={'4k'}>4k</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Frame Rate"
            name="Frame Rate"
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
          >
            <Select placeholder="Frame Rate" value={framerate} defaultValue={'29.97fps'} onChange={handleFrameRate}>
              <Select.Option value={'10fps'}>10 fps</Select.Option>
              <Select.Option value={'12fps'}>12 fps</Select.Option>
              <Select.Option value={'15fps'}>15 fps</Select.Option>
              <Select.Option value={'23.976fps'}>23.976 fps</Select.Option>
              <Select.Option value={'24fps'}>24 fps</Select.Option>
              <Select.Option value={'25fps'}>25 fps</Select.Option>
              <Select.Option value={'29.97fps'}>29.97 fps</Select.Option>
              <Select.Option value={'30fps'}>30 fps</Select.Option>
              <Select.Option value={'50fps'}>50 fps</Select.Option>
              <Select.Option value={'60fps'}>60 fps</Select.Option>
              <Select.Option value={'120fps'}>120 fps</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Format"
            name="Format"
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
          >
            <Select placeholder="Format" value={format} defaultValue={'h.264'} onChange={handleFormat}>
              <Select.Option value={'mov'}>mov</Select.Option>
              <Select.Option value={'avi'}>avi</Select.Option>
              <Select.Option value={'h.264'}>h.264</Select.Option>
              <Select.Option value={'HEVC'}>HEVC</Select.Option>
              <Select.Option value={'mp4'}>mp4</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Preview Link"
            name="Preview Link"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Preview Link" value={previewFile} onChange={(e) => setPreviewFile(e.target.value)} />
          </Form.Item>
          <Form.Item
            label="Categories"
            name="Category"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TreeSelect
              showSearch
              style={{ width: '100%', cursor: 'pointer' }}
              value={categories} // The selected value(s)
              dropdownStyle={{ overflow: 'auto' }}
              placeholder="Please select"
              allowClear
              multiple // Allows selecting multiple options
              treeDefaultExpandAll
              onChange={onChange} // Event handler for changes
              treeData={treeData} // The transformed data
            />
          </Form.Item>
          <Form.Item
            label="File Size"
            name="File Size"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              addonAfter={
                <Select value={sizeUnit} onChange={handleSizeUnitChange} style={{ width: 70 }}>
                  {selectSizeUnit.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              }
              defaultValue={0}
              value={sizeValue}
              onChange={handleSizeValueChange}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            label="Duration"
            name="Time Duration"
            rules={[
              {
                required: true,
              },
            ]}
            // style={{ background: 'black' }}
          >
            <TimePicker
              value={pickerValue}
              // width={'1000px'}
              onChange={onDurationChange}
              format="mm:ss"
              showNow={false}
              onOk={onOk} // Handle OK click
            />
          </Form.Item>
          {/* <Form.Item
            label="Duration"
            name="Time Duration"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              addonAfter={
                <Select value={durationUnit} onChange={handleDurationUnitChange} style={{ width: 70 }}>
                  {selectTimeUnit.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              }
              defaultValue={0}
              value={durationValue}
              onChange={handleDurationValueChange}
              // size="large"
              style={{ width: '100%' }}
            />

           
          </Form.Item> */}
          <Form.Item
            label="Thumbnail"
            name="Thumbnail"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <div>
              <label htmlFor="fileUpload">
                <input
                  type="file"
                  id="fileUpload"
                  name="fileUpload"
                  // value={thumbnail}
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </label>
            </div>
          </Form.Item>
          <>
            <div style={{ height: '500px' }}>
              <ReactQuill
                value={content}
                onChange={handleChange}
                modules={{ toolbar: toolbarOptions }}
                formats={formats} // Specify allowed formats, including links
                style={{ height: '400px' }}
              />
            </div>
            {/* <button onClick={()=>console.log(content)}>Click me</button> */}
          </>
          <Grid container justifyContent={'center'}>
            <Grid item>
              <Form.Item label=" ">
                {/* <Button type="primary" htmlType="submit">
                  Submit
                </Button> */}
                <LoadingButton type="submit" variant="contained" loading={isLoading}>
                  Submit
                </LoadingButton>
              </Form.Item>
            </Grid>
          </Grid>
          {/* <NumberInput /> */}
          {/* <FileSizeInput /> */}
        </Form>

        {/* <form onSubmit={handleSubmit}>
          <Grid spacing={3} container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {' '}
              <TextField
                name="title"
                fullWidth
                value={videoTitle}
                onChange={(e) => setVideoTitle(e.target.value)}
                label="Video Title"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                name="link"
                fullWidth
                value={link}
                onChange={(e) => setLink(e.target.value)}
                label="Video Link"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {' '}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Resolution</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={resolution}
                  onChange={(e) => setResolution(e.target.value)}
                  label="Resolution"
                  // onChange={handleChange}
                >
                  <MenuItem value={'720p'}>720p</MenuItem>
                  <MenuItem value={'1080p'}>1080p</MenuItem>
                  <MenuItem value={'2k'}>2k</MenuItem>
                  <MenuItem value={'4k'}>4k</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {' '}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Frame Rate</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={framerate}
                  onChange={(e) => setFramerate(e.target.value)}
                  label="Frame Rate"
                  // onChange={handleChange}
                >
                  <MenuItem value={'10fps'}>10fps</MenuItem>
                  <MenuItem value={'12fps'}>12fps</MenuItem>
                  <MenuItem value={'15fps'}>15fps</MenuItem>
                  <MenuItem value={'23.976fps'}>23.976fps</MenuItem>
                  <MenuItem value={'24fps'}>24fps</MenuItem>
                  <MenuItem value={'25fps'}>25fps</MenuItem>
                  <MenuItem value={'29.97fps'}>29.97fps</MenuItem>
                  <MenuItem value={'30fps'}>30fps</MenuItem>
                  <MenuItem value={'50fps'}>50fps</MenuItem>
                  <MenuItem value={'60fps'}>60fps</MenuItem>
                  <MenuItem value={'120fps'}>120fps</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Format</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={format}
                  onChange={(e) => setFormat(e.target.value)}
                  label="Format"
                  // onChange={handleChange}
                >
                  <MenuItem value={'mov'}>mov</MenuItem>
                  <MenuItem value={'avi'}>avi</MenuItem>
                  <MenuItem value={'h.264'}>h.264</MenuItem>
                  <MenuItem value={'HEVC'}>HEVC</MenuItem>
                  <MenuItem value={'mp4'}>mp4</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {' '}
              <TextField
                name="previewfile"
                fullWidth
                value={previewFile}
                onChange={(e) => setPreviewFile(e.target.value)}
                label="Preview File Link"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TreeSelect
                showSearch
                style={{ width: '100%', height: '40px', cursor: 'pointer' }}
                value={value} // The selected value(s)
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Please select"
                allowClear
                multiple // Allows selecting multiple options
                treeDefaultExpandAll
                onChange={onChange} // Event handler for changes
                treeData={treeData} // The transformed data
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <NumberInput />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FileSizeInput />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div>
                <form>
                  <div>
                    Upload thumbnail:
                    <label htmlFor="fileUpload">
                      <input
                        type="file"
                        id="fileUpload"
                        name="fileUpload"
                        onChange={handleFileChange}
                        accept="image/*"
                      />
                    </label>
                  </div>
                </form>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              description
              <Help />
            </Grid>

            <Grid container justifyContent="center">
              <Grid item>
                <LoadingButton sx={{ marginTop: '20px' }} loading={isLoading} type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </form> */}

        {/* <List>
          {videos?.map((video) => (
            <ListItem key={video._id}>
              <ListItemText primary={video.videoTitle} secondary={`Categories: ${video.categories.join(', ')}`} />
              <img src={`http://localhost:5000/${video?.thumbnail}`} alt="thumbnail" />

              {video?.thumbnail}
            </ListItem>
          ))}
        </List> */}
        {/* <img
          src='http://localhost:5000/uploads\thumbnails\1715354997797-819224739-WhatsApp Image 2024-05-03 at 7.24.09 AM.jpeg'
          alt="thumbnail"
        /> */}
      </Container>
    </>
  );
}
