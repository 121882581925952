import React from 'react';
import { useLocation } from 'react-router-dom';

export default function ModelDetails() {
  const location = useLocation();
  const { model } = location.state;
  console.log(model);
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>{model?.title}</h1>
      <div>
        <div dangerouslySetInnerHTML={{ __html: model?.description }} />
      </div>
      <div>
        tags:
        {model?.tags?.map((tag) => (
          <div key={tag}>{tag}</div>
        ))}
      </div>
    </div>
  );
}
