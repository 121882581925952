import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

// import Video from './Coorporate buildings.webm'

const VideoDetails = () => {
  const location = useLocation();
  const { video } = location.state;
  console.log(video);
  return (
    <div style={{ marginTop: '100px' }}>
      <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>{video?.videoTitle}</h1>

      <div dangerouslySetInnerHTML={{ __html: video?.description }} />

      <div style={{ textAlign: 'center', margin: '30px' }}>
        Video Link: {video?.videolink} <br />
        Frame Rate: {video?.framerate} <br />
        Format:{video?.format} <br />
        resolution:{video?.resolution} <br />
        timeDuration:{video?.timeDuration} <br />
        fileSize:{video?.fileSize} <br />
        Video Link: {video?.videolink} <br />
        Preview Link: {video?.preivewfile} <br />
        {/* {downloadProgress > 0 && <p>Download Progress: {downloadProgress.toFixed(2)}%</p>} */}
      </div>

      {/* description:{video?.description} <br /> */}
    </div>
  );
};

export default VideoDetails;
