import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const renderPageNumbers = () => {
    const pages = [];
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    if (currentPage <= 3) {
      endPage = Math.min(totalPages, 5);
    }
    
    if (currentPage > totalPages - 3) {
      startPage = Math.max(1, totalPages - 4);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "20px", maxWidth: "1100px", margin: "0 auto" }}>
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        style={{
          margin: "0 5px",
          padding: "5px 10px",
          backgroundColor: "gray",
          color: "white",
        }}
      >
        Previous
      </button>
      
      {currentPage > 3 && (
        <>
          <button
            onClick={() => onPageChange(1)}
            style={{
              margin: "0 5px",
              padding: "5px 10px",
              backgroundColor: currentPage === 1 ? "blue" : "gray",
              color: "white",
            }}
          >
            1
          </button>
          {currentPage > 4 && <span style={{ margin: "0 5px" }}>...</span>}
        </>
      )}
      
      {renderPageNumbers().map((page) => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          style={{
            margin: "0 5px",
            padding: "5px 10px",
            backgroundColor: currentPage === page ? "blue" : "gray",
            color: "white",
          }}
        >
          {page}
        </button>
      ))}

      {currentPage < totalPages - 2 && (
        <>
          {currentPage < totalPages - 3 && <span style={{ margin: "0 5px" }}>...</span>}
          <button
            onClick={() => onPageChange(totalPages)}
            style={{
              margin: "0 5px",
              padding: "5px 10px",
              backgroundColor: currentPage === totalPages ? "blue" : "gray",
              color: "white",
            }}
          >
            {totalPages}
          </button>
        </>
      )}
      
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        style={{
          margin: "0 5px",
          padding: "5px 10px",
          backgroundColor: "gray",
          color: "white",
        }}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
