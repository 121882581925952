import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import axios from 'axios';
import { getVideos } from '../Services/videoServices';
import BASE_URL from '../BASE_URL';
import Test from '../components/Test/Test';
import Pagination from '../components/Pagination/Pagination';

// import { useState } from 'react';

function createData(videoTitle, resolution, framerate, format) {
  return { videoTitle, resolution, framerate, format };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function BasicTable() {
  const { page } = useParams();
  const [videos, setVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const navigate = useNavigate();
  // useEffect(() => {
  //   const fetchVideos = async () => {
  //     try {
  //       const response = await axios.get(`${BASE_URL}/api/video/get-videos-admin`);
  //       setVideos(response.data);
  //     } catch (err) {
  //       setError('Error fetching videos');
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchVideos();
  // }, []);

  const fetchVideos = async (page = 1, limit = 18) => {
    try {
      const data = await getVideos(page, limit);
      setVideos(data.videos);
      setCurrentPage(data.currentPage);
      setTotalPages(data.totalPages);
    } catch (err) {
      setError('Error fetching videos');
    }
  };

  useEffect(() => {
    fetchVideos(currentPage);
  }, [currentPage]);

  const handleCilck = () => {
    navigate('/dashboard/videos');
  };
  const handleVideo = (row) => {
    navigate('/dashboard/video-details', {
      state: {
        // ...values
        video: row,
      },
    });
  };

  const handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete this video?');

      if (confirmDelete) {
        const response = await axios.delete(`${BASE_URL}/api/video/delete-video/${id}`);

        if (response.status === 200) {
          // alert('Video deleted successfully');
          setVideos(videos.filter((video) => video._id !== id));
        } else {
          console.error('Failed to delete video');
        }
      }
    } catch (error) {
      console.error('Error deleting video:', error);
    }
  };
  const handleUpdate = (row) => {
    navigate('/dashboard/update-video', {
      state: {
        // ...values
        video: row,
      },
    });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);

    console.log('page via params', page, 'new page on pagination handle chane', newPage);

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1000);
    navigate(`/dashboard/videos-list/${newPage}`);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/dashboard/videos-list/${title}/1`);
  };
  return (
    <>
      <h1>Videos</h1>
      <h1>Search Videos</h1>
      <form onSubmit={handleSubmit}>
        <input
          required
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter video title"
        />
        <button type="submit">Search</button>
      </form>
      <Button variant="contained" sx={{ float: 'right' }} onClick={() => handleCilck()}>
        Add video
      </Button>
      <TableContainer style={{ overflow: 'auto' }} component={Paper}>
        <Table sx={{ minWidth: 650, overflow: 'auto' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Video Title</TableCell>
              <TableCell align="right">Resolution</TableCell>
              <TableCell align="right">Frame Rate</TableCell>
              <TableCell align="right">Format</TableCell>
              <TableCell align="right">Action</TableCell>
              <TableCell align="right">Delete Video</TableCell>
              <TableCell align="right">Update Video</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {videos?.map((row) => (
              <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.videoTitle}
                </TableCell>
                <TableCell align="right">{row.resolution}</TableCell>
                <TableCell align="right">{row.framerate}</TableCell>
                <TableCell align="right">{row.format}</TableCell>
                {/* <TableCell align="right">{row.protein}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell> */}
                <TableCell align="right">
                  <Button onClick={() => handleVideo(row)}>View</Button>
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => handleDelete(row?._id)}>Delete</Button>
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => handleUpdate(row)}>Update</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Test /> */}
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
    </>
  );
}
