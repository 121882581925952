import React from 'react';
import { useLocation } from 'react-router-dom';
import BASE_URL from '../../BASE_URL';

export default function QueryDetails() {
  const location = useLocation();
  const { query } = location.state;
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Query Details</h1>
      name: {query?.name}
      <br />
      email: {query?.email}
      <br />
      phone: {query?.phone}
      <br />
      identity: {query?.identity}
      <br />
      duration: {query?.duration}
      <br />
      purpose: {query?.purpose}
      <br />
      country: {query?.country}
      <br />
      city: {query?.city}
      <br />
      deadline: {query?.deadline}
      <br />
      inspirationalLinks:{' '}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <p>
          inspirationalLinks:<span style={{ color: 'white' }}>......</span>
        </p>
        {query?.inspirationalLinks?.map((link, index) => (
          <div key={index}>{link},</div>
        ))}
      </div>
      <br />
      attachments:{' '}
      {query?.attachments?.map((attachment, index) => (
        <div key={index}>
          <img style={{ margin: '0 auto' }} src={`${BASE_URL}/${attachment}`} alt="thumbnail" />
        </div>
      ))}
      <br />
    </div>
  );
}
