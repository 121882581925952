import { LoadingButton } from '@mui/lab';
// import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { Form, Input } from 'antd';
import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateBlog } from '../../Services/blogServices';

const EditBlog = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const { blog } = location.state;
  const editorRef = useRef(blog?.content);
  const [thumbnail, setThumbnail] = useState(null);
  const [blogTitle, setBlogTitle] = useState(blog?.blogTitle);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    // e.preventDefault();
    const content = editorRef.current.getContent();
    setLoading(true);

    try {
      const blogData = {
        blogTitle,
        content,
        thumbnail,
      };
      const response = await updateBlog(blog?._id, blogData);

      console.log(response);
      alert('Blog Updated Successfully');
      navigate('/dashboard/blog-list');
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === 'Blog title must be unique'
      ) {
        alert('Blog title must be unique');
      } else {
        alert('Blog adding video');
      }
      //   console.log('Error adding blog: ', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    setThumbnail(event.target.files[0]);
  };

  return (
    <>
      <h1>Add Blog</h1>
      <Form
        name="wrap"
        onFinish={handleSubmit}
        labelCol={{
          flex: '110px',
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        style={{
          width: '100%',
        }}
      >
        <Form.Item
          label="Blog Title"
          name="blogTitle"
          //   rules={[
          //     {
          //       required: true,
          //     },
          //   ]}
        >
          <Input
            placeholder="Blog Title"
            value={blogTitle}
            defaultValue={blogTitle}
            // defaultValue={videoTitle}
            onChange={(e) => setBlogTitle(e.target.value)}
          />
        </Form.Item>

        {/* <Form.Item
          label="Blog Title"
          name="content"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder="Blog Title"
            value={content}
            // defaultValue={videoTitle}
            onChange={(e) => setContent(e.target.value)}
          />
        </Form.Item> */}

        <Form.Item
          label="Thumbnail"
          name="thumbnail"
          //   rules={[
          //     {
          //       required: true,
          //     },
          //   ]}
        >
          <div>
            <label htmlFor="fileUpload">
              <input
                type="file"
                id="fileUpload"
                name="fileUpload"
                // value={thumbnail}
                onChange={handleFileChange}
                accept="image/*"
              />
            </label>
            <p>{blog?.thumbnail}</p>
          </div>
        </Form.Item>
        <>
          <Editor
            apiKey="9uej4yr18x8j7w9p6vg9m6e73l6uqhuxib8dsl92l0pzh2jt"
            onInit={(evt, editor) => {
              editorRef.current = editor; // Assigning editor reference to useRef variable
            }}
            initialValue={blog?.content}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'code',
                'help',
                'wordcount',
              ],
              toolbar:
                'undo redo | formatselect | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | link image | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
        </>
        <Grid container justifyContent={'center'}>
          <Grid item>
            <Form.Item label=" ">
              {/* <Button type="primary" htmlType="submit">
                  Submit
                </Button> */}
              <LoadingButton type="submit" variant="contained">
                Submit
              </LoadingButton>
            </Form.Item>
          </Grid>
        </Grid>
        {/* <NumberInput /> */}
        {/* <FileSizeInput /> */}
      </Form>
    </>
  );
};

export default EditBlog;
