import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, Input, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Form } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addCategoryCard } from '../../Services/videoCategoryServices';
import BASE_URL from '../../BASE_URL';

const AddCard = () => {
  const navigate = useNavigate();
  const [thumbnail, setThumbnail] = useState(null);
  const [categoryTitle, setCategoryTitle] = useState('');
  const [link, setLink] = useState('');

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    // e.preventDefault();

    setLoading(true);
    const formData = new FormData();
    formData.append('categoryTitle', categoryTitle);
    formData.append('CategoryId', parent);
    formData.append('link', link);
    if (thumbnail) {
      formData.append('thumbnail', thumbnail);
    }

    try {
      const response = await addCategoryCard(formData);

      // console.log(response);
      alert('Category Card added Successfully');
      navigate('/dashboard/card-list');
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === 'Blog title must be unique'
      ) {
        alert('Blog title must be unique');
      } else {
        alert('Error adding video');
      }
      //   console.log('Error adding blog: ', error.message);
    } finally {
      setLoading(false);
    }
  };
  //   categoryTitle
  //   CategoryId
  //   thumbnail

  const [parent, setParent] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch existing categories for parent selection
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/category/get-categories`);
        setCategories(response.data);
      } catch (err) {
        setError('Error fetching categories');
      }
    };

    fetchCategories();
  }, []);

  const handleFileChange = (event) => {
    setThumbnail(event.target.files[0]);
  };

  const handleChange = (e) => {
    // e.preventDefault();
    // e.target.value;
    // console.log(e.target.value);
    setParent(e.target.value);
  };
  const handleClick = (name) => {
    setCategoryTitle(name);
    // console.log('naam', name);
  };
  return (
    <>
      <h1>Add Category Card</h1>
      <Form
        name="wrap"
        onFinish={handleSubmit}
        labelCol={{
          flex: '110px',
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        style={{
          width: '100%',
        }}
      >
        <Form.Item
          label={
            <div>
              <span style={{ color: 'red' }}>*</span> Title
            </div>
          }
          name="categoryTitle"

          //   labelAlign='center'
        >
          <FormControl fullWidth>
            <InputLabel>Category Title</InputLabel>
            <Select
              label="Category Title"
              value={parent}
              //   defaultValue={'666dc5ff78d564794f3c66a3'}
              onChange={(e) => handleChange(e)}
              fullWidth
            >
              <MenuItem value={null}>None (Top Level)</MenuItem>
              {categories?.map((category) => (
                <MenuItem key={category._id} value={category._id} onClick={() => handleClick(category.name)}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Form.Item>

        <Form.Item
          label="Link"
          name="link"
          rules={[
            {
              required: true,
              message: 'Please add link',
            },
          ]}
        >
          <TextField
            id="outlined-multiline-flexible"
            label="Link"
            fullWidth
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label="Thumbnail"
          name="thumbnail"
          style={{ marginTop: '30px' }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <div>
            <label htmlFor="fileUpload">
              <input
                type="file"
                id="fileUpload"
                name="fileUpload"
                // value={thumbnail}
                onChange={handleFileChange}
                accept="image/*"
              />
            </label>
          </div>
        </Form.Item>

        <Grid container justifyContent={'center'}>
          <Grid item>
            <Form.Item label=" ">
              {/* <Button type="primary" htmlType="submit">
                  Submit
                </Button> */}
              <LoadingButton type="submit" variant="contained">
                Submit
              </LoadingButton>
            </Form.Item>
          </Grid>
        </Grid>
        {/* <NumberInput /> */}
        {/* <FileSizeInput /> */}
      </Form>
    </>
  );
};

export default AddCard;
