import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import axios from 'axios';
import { getTags } from '../../Services/axialServices';
import Pagination from '../../components/Pagination/Pagination';
import BASE_URL from '../../BASE_URL';

// import { useState } from 'react';

function createData(videoTitle, resolution, framerate, format) {
  return { videoTitle, resolution, framerate, format };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function AllTags() {
  const [audioTags, setAudioTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchAudioTags = async (page = 1, limit = 20) => {
    try {
      const data = await getTags(); // Fetch 20 tags per page
      setAudioTags(data);
      // setCurrentPage(data.currentPage);
      // setTotalPages(data.totalPages);
      // console.log(data.audioTags);
    } catch (err) {
      setError('Error fetching audio tags');
    }
  };

  useEffect(() => {
    fetchAudioTags(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete this video?');

      if (confirmDelete) {
        const response = await axios.delete(`${BASE_URL}/api/audio/delete-audio-tag/${id}`);

        if (response.status === 200) {
          // alert('Video deleted successfully');
          setAudioTags(audioTags.filter((tag) => tag?._id !== id));
        } else {
          console.error('Failed to delete audio tag');
        }
      }
    } catch (error) {
      console.error('Error deleting audio tag:', error);
    }
  };

  const handleCilck = () => {
    navigate('/dashboard/add-model-tag');
    // console.log('ammb a yr ');
  };
  return (
    <>
      <h1>3D Model Tags</h1>
      <Button variant="contained" sx={{ float: 'right' }} onClick={() => handleCilck()}>
        3D model Tags
      </Button>
      <TableContainer style={{ overflow: 'auto' }} component={Paper}>
        <Table sx={{ minWidth: 650, overflow: 'auto' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Tag Title</TableCell>
              <TableCell align="right">Delete Tag</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {audioTags?.map((row, index) => (
              <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row?.name}
                </TableCell>
                <TableCell align="right">
                  <Button 
                  // onClick={() => handleDelete(row?._id)}
                  >Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
    </>
  );
}
