import React, { useEffect, useState } from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BASE_URL from '../../BASE_URL';

export default function ContactList() {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const getContacts = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/contact/get-contacts`);
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching contact messages:', error);
      throw error;
    }
  };

  useEffect(() => {
    getContacts();
  }, []);
  const handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete this Query?');
      if (confirmDelete) {
        const response = await axios.delete(`${BASE_URL}/api/contact/delete-contact/${id}`);
        if (response.status === 200) {
          setMessages(messages.filter((query) => query._id !== id));
        } else {
          console.error('Failed to delete Query');
        }
      }
    } catch (error) {
      console.error('Error deleting contact message:', error);
      throw error;
    }
  };
  return (
    <div>
      <h1>Messages</h1>
      <TableContainer style={{ overflow: 'auto' }} component={Paper}>
        <Table sx={{ minWidth: 650, overflow: 'auto' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Phone</TableCell>
              <TableCell align="left">View</TableCell>
              <TableCell align="left">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages?.map((row) => (
              <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left" scope="row">
                  {row?.name}
                </TableCell>
                <TableCell align="left" scope="row">
                  {row?.email}
                </TableCell>
                <TableCell align="left" scope="row">
                  {row?.mobileNumber}
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() =>
                      navigate('/dashboard/contact-details', {
                        state: {
                          contact: row,
                        },
                      })
                    }
                  >
                    View
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button onClick={() => handleDelete(row?._id)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
