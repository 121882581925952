import axios from 'axios';
import BASE_URL from '../BASE_URL';

export const getAudios = async (page, limit) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/audio/get-audios`, {
      params: { page, limit },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching paginated audio records:', error);
    throw error;
  }
};
export const searchAudio = async (queryParams) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/audio/search`, {
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    console.error('Error searching audio:', error);
    throw error;
  }
};

export const searchAudiosByTags = async (tags, page, limit) => {
  try {
    const params = new URLSearchParams({ tags, page, limit });
    const response = await axios.get(`${BASE_URL}/api/audio/search-by-tags?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error('Error searching audios by tags:', error);
    throw error;
  }
};

export const audioCategoryById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/audio/findcategory/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching category by ID:', error);
    throw error;
  }
};

export const getAudiosByCategoryName = async (categoryName, page, limit) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/audio/search-by-category`, {
      params: { categoryName, page, limit },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching audios by category name:', error);
    throw error;
  }
};

export const getAudioTags = async (page, limit) => {
  try {
    const params = new URLSearchParams({ page, limit });
    const response = await axios.get(`${BASE_URL}/api/audio/get-audio-tags?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching audio tags:', error);
    throw error;
  }
};

export const searchAudiosByTitle = async (title, page = 1, limit = 20) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/audio/search-by-title`, {
      params: {
        title,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error searching audios by title:", error);
    throw error;
  }
};