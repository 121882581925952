import axios from "axios";
import BASE_URL from "../BASE_URL";

export const addQuery = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/query/add-query`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error adding query:", error);
    throw error;
  }
};

export const getQueryById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/query/get-query/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching query by ID:", error);
    throw error;
  }
};

export const updateQueryById = async (id, updateFields) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/api/query/update-query/${id}`,
      updateFields
    );
    return response.data;
  } catch (error) {
    console.error("Error updating query:", error);
    throw error;
  }
};

export const deleteQueryById = async (id) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/api/query/delete-query/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting query:", error);
    throw error;
  }
};

export const updateAttachments = async (id, attachments) => {
  try {
    const formData = new FormData();
    attachments.forEach((file, index) => {
      formData.append(`attachments`, file);
    });

    const response = await axios.put(
      `${BASE_URL}/api/query/update-attachments/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error updating attachments:", error);
    throw error;
  }
};

export const getQueries = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/query/get-queries`);
    return response.data;
  } catch (error) {
    console.error("Error fetching queries:", error);
    throw error;
  }
};
