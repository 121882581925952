import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import axios from 'axios';
import { searchAudiosByTitle } from '../../Services/audioServices';
import BASE_URL from '../../BASE_URL';


export default function SearchByTitle() {
    const { paramspage } = useParams();
    const { paramstitle } = useParams();
    const navigate = useNavigate();

    const [title, setTitle] = useState(paramstitle);
    const [audios, setAudios] = useState([]);
    const [page, setPage] = useState(paramspage);
    const [totalPages, setTotalPages] = useState(0);
    const [totalVideos, setTotalVideos] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSearch = async (limit = 100) => {
        navigate(`/dashboard/audio-list/${title}/1`);
        setLoading(true);
        setError('');
        try {
          const data = await searchAudiosByTitle(title, page, limit);
          setAudios(data.audios);
          setTotalPages(data.totalPages);
          setTotalVideos(data.totalAudios);
        } catch (err) {
          setError('Error searching videos');
        } finally {
          setLoading(false);
        }
      };

      const handleAudio = (row) => {
        navigate('/dashboard/audio-details', {
          state: {
            // ...values
            audio: row,
          },
        });
      };
    
      const handleDelete = async (id) => {
        try {
          const confirmDelete = window.confirm('Are you sure you want to delete this video?');
    
          if (confirmDelete) {
            const response = await axios.delete(`${BASE_URL}/api/audio/delete-audio/${id}`);
    
            if (response.status === 200) {
              // alert('Video deleted successfully');
              setAudios(audios.filter((video) => video._id !== id));
            } else {
              console.error('Failed to delete video');
            }
          }
        } catch (error) {
          console.error('Error deleting video:', error);
        }
      };
      const handleUpdate = (row) => {
        navigate('/dashboard/update-audio', {
          state: {
            // ...values
            audio: row,
          },
        });
      };
      const handleCilck = () => {
        navigate('/dashboard/add-audio');
      };
      useEffect(() => {
        handleSearch();
      }, [paramstitle]);
  return (
    <>
    <h1>Audios</h1>
    <h1>Search Audios</h1>
    <form onSubmit={handleSearch}>
      <input
        required
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Enter Audio title"
      />
      <button type="submit">Search</button>
    </form>
    <Button variant="contained" sx={{ float: 'right' }} onClick={() => handleCilck()}>
      Add Audio
    </Button>
    <TableContainer style={{ overflow: 'auto' }} component={Paper}>
      <Table sx={{ minWidth: 650, overflow: 'auto' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Audio Title</TableCell>
            {/* <TableCell align="right">Audio Link</TableCell> */}
            <TableCell align="right">View</TableCell>
            <TableCell align="right">Delete</TableCell>
            <TableCell align="right">Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {audios?.map((row) => (
            <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.audioTitle}
              </TableCell>
              {/* <TableCell align="right">{row.link}</TableCell> */}
              {/* <TableCell align="right">{row.framerate}</TableCell>
              <TableCell align="right">{row.format}</TableCell> */}
              {/* <TableCell align="right">{row.protein}</TableCell>
            <TableCell align="right">{row.carbs}</TableCell> */}
              <TableCell align="right">
                <Button onClick={() => handleAudio(row)}>View</Button>
              </TableCell>
              <TableCell align="right">
                <Button onClick={() => handleDelete(row?._id)}>Delete</Button>
              </TableCell>
              <TableCell align="right">
                <Button onClick={() => handleUpdate(row)}>Update</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {/* <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
  </>
  )
}
