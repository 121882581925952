import axios from "axios";
import BASE_URL from "../BASE_URL";

export const getVideos = async (page, limit) => {
  try {
    const params = new URLSearchParams({ page, limit });
    const response = await axios.get(
      `${BASE_URL}/api/video/get-videos?${params.toString()}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching videos:", error);
    throw error;
  }
};

export const videoCategoryById = async (id) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/category/findcategory/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching category by ID:", error);
    throw error;
  }
};

export const videoOnlyParentsCategories = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/category/get-parent-categories`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Parents Category:", error);
    throw error;
  }
};

export const searchVideosByTitle = async (title, page, limit) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/video/search-by-title`, {
      params: {
        title,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error searching videos by title:", error);
    throw error;
  }
};