import axios from 'axios';

import BASE_URL from '../BASE_URL';

export const addBlog = async (blogData) => {
  try {
    const formData = new FormData();
    formData.append('blogTitle', blogData.blogTitle);
    formData.append('content', blogData.content);
    if (blogData.thumbnail) {
      formData.append('thumbnail', blogData.thumbnail);
    }

    const response = await axios.post(`${BASE_URL}/api/blog/add-blog`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error adding blog:', error);
    throw error;
  }
};

export const updateBlog = async (id, blogData) => {
  try {
    const formData = new FormData();
    formData.append('blogTitle', blogData.blogTitle);
    formData.append('content', blogData.content);
    if (blogData.thumbnail) {
      formData.append('thumbnail', blogData.thumbnail);
    }

    const response = await axios.put(`${BASE_URL}/api/blog/update-blog/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error updating blog:', error);
    throw error;
  }
};

export const deleteBlog = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/api/blog/delete-blog/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting blog:', error);
    throw error;
  }
};

export const getBlogById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/blog/get-blog/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching blog:', error);
    throw error;
  }
};

export const getAllBlogs = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/blog/get-blogs`);
    return response.data;
  } catch (error) {
    console.error('Error fetching blogs:', error);
    throw error;
  }
};
