import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import axios from 'axios';
import { getAllBlogs } from '../../Services/blogServices';
import Pagination from '../../components/Pagination/Pagination';
import BASE_URL from '../../BASE_URL';

export default function BlogList() {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const fetchBlogs = async () => {
    try {
      const response = await getAllBlogs();
      setBlogs(response);
      //   setCurrentPage(data.currentPage);
      //   setTotalPages(data.totalPages);
    } catch (err) {
      setError('Error fetching blogs');
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleAddClick = () => {
    navigate('/dashboard/add-blog');
  };

  const handleViewBlog = (row) => {
    navigate('/dashboard/blog-details', {
      state: {
        blog: row,
      },
    });
  };

  const handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete this blog?');

      if (confirmDelete) {
        const response = await axios.delete(`${BASE_URL}/api/blog/delete-blog/${id}`);

        if (response.status === 200) {
          setBlogs(blogs.filter((blog) => blog._id !== id));
        } else {
          console.error('Failed to delete blog');
        }
      }
    } catch (error) {
      console.error('Error deleting blog:', error);
    }
  };

  const handleUpdate = (row) => {
    navigate('/dashboard/update-blog', {
      state: {
        blog: row,
      },
    });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1000);
  };

  return (
    <>
      <h1>Blogs</h1>
      <Button variant="contained" sx={{ float: 'right' }} onClick={handleAddClick}>
        Add Blog
      </Button>
      <TableContainer style={{ overflow: 'auto' }} component={Paper}>
        <Table sx={{ minWidth: 650, overflow: 'auto' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Blog Title</TableCell>
              <TableCell align="right">View</TableCell>
              <TableCell align="right">Delete</TableCell>
              <TableCell align="right">Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blogs?.map((row) => (
              <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.blogTitle}
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => handleViewBlog(row)}>View</Button>
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => handleDelete(row?._id)}>Delete</Button>
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => handleUpdate(row)}>Update</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
    </>
  );
}
