import { Helmet } from 'react-helmet-async';

// @mui
// import { Container, Typography } from '@mui/material';
import {
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  TextField,
  Typography
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BASE_URL from '../BASE_URL';

// ----------------------------------------------------------------------

export default function AudioTags() {
  const [categoryName, setCategoryName] = useState('');
  //   const [parent, setParent] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  //   useEffect(() => {
  //     // Fetch existing categories for parent selection
  //     const fetchCategories = async () => {
  //       try {
  //         const response = await axios.get(`${BASE_URL}/api/audio/get-categories`);
  //         setCategories(response.data);
  //       } catch (err) {
  //         setError('Error fetching categories');
  //       }
  //     };

  //     fetchCategories();
  //   }, []);

  const handleAddCategory = async () => {
    if (!categoryName) {
      setError('Category name is required');
      return;
    }

    setIsLoading(true);

    try {
      const newCategory = {
        name: categoryName,
      };

      await axios.post(`${BASE_URL}/api/audio/add-audio-tag`, newCategory);

      setCategoryName(''); // Clear the text field
      //   setParent(null); // Reset the dropdown
      alert('Tag added successfully');
      navigate('/dashboard/audio-tags')
    } catch (err) {
      setError('Error adding category');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title> Categories </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Audio Tags
        </Typography>

        <div>
          {error && <FormHelperText error>{error}</FormHelperText>}
          <TextField
            label="Tag Title"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            fullWidth
            sx={{ mb: '10px' }}
          />
          {/* <FormControl sx={{ marginTop: '15px', marginBottom: '15px' }} fullWidth>
            <InputLabel>Parent Category</InputLabel>
            <Select label="Parent Category" value={parent} onChange={(e) => setParent(e.target.value)} fullWidth>
              <MenuItem value={null}>None (Top Level)</MenuItem>
              {categories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <Button
            sx={{ marginTop: '10px' }}
            variant="contained"
            color="primary"
            onClick={handleAddCategory}
            disabled={isLoading}
            fullWidth
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Add Tag'}
          </Button>
        </div>
      </Container>
    </>
  );
}
